/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Door4/CHAHIN_DOOR.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.01, 0.01, 0.13]}>
        <group position={[0.01, -0.01, -0.13]}>
          <mesh 
            material={materials['Material.008']} 
            geometry={nodes['node-0'].geometry} 
          />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Door4/CHAHIN_DOOR.gltf')
