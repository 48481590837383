const books = [
  {
    category: 'Books',
    chat: [
      `you know, after listing these it feels a bit disorganized`,
      `I'm not sure how to better do this..`, 
      `I'm trying not to filter what my bookcase looks like`,
    ],
    items: [
      {
        author: `Douglas Adams`,
        title: `The Hitchhiker's Guide to the Galaxy`,
        chat: [
          `It's a fun read`,
          `The pacing is really well done and helps the humor a lot`,
        ],
      }, {
        author: `Marcus Aurelius`,
        title: `Meditations`,
        chat: [
          `I dig it`,
          `it's repetitive, but so are our lives`,
        ],
      }, {
        author: `James Baldwin`,
        title: `Go Tell it on the Mountain`,
        chat: [
          `This was a hard book to read, but really good`,
          `There's a lot of precision in Baldwin's writing`,
          `I like his earlier stuff a little more because I don't think he knew *exactly* what he was saying then`,
          `I think the best writing probably comes when you're a little out of your depth`,
        ],
      }, {
        author: `James Baldwin`,
        title: `If Beale Street Could Talk`,
        chat: [
          `It's a really powerful novel`,
          `I think the most striking thing is how little has changed in terms of policing since then.`,
          `He captures the feelings of it all so well`,
        ],
      }, {
        author: `James Baldwin`,
        title: `The Cross of Redemption: Uncollected Writings`,        
        chat: [
          `Anytime I read Baldwin, I think.. that dude could write`,
          `the preface describes his writing as being like a sermon and I like that`,
          `it has that fire`,
          `there's a couple pieces that aren't in this I want to recommend`,
          `[If Black English Isn't a Language, Then Tell Me, What Is?](https://archive.nytimes.com/www.nytimes.com/books/98/03/29/specials/baldwin-english.html)`,
          `[Autobiographical Notes](https://archive.nytimes.com/www.nytimes.com/books/first/b/baldwin-essays.html?mcubz=3)`,
        ],
      }, {
        author: `Phillip Ball`,
        title: `Patterns in Nature`,
        chat: [
          `I got this as a reference`,
          `and it has a lot of pretty pictures`,
        ],
      }, {
        author: `Honoré de Balzac`,
        title: `The Human Comedy`,
        chat: [
          `It feels so.. French`,
          `I guess how it lingers and takes its time`,
          `there's something nice about being steeped in it`,
          `but I can't always get myself to keep going`,
        ],
      }, {
        author: `Ruha Benjamin`,
        title: `Race After Technology`,
        chat: [
          `I appreciated the way it connected the development of technology with race`,
          `I think that I understood 'whiteness' quite a bit more by how she framed it`,
          `I'm still working through the ways computers are about power`,
          `and how easy it is to reinforce existing power structures`,
        ],
      }, {
        author: `Alain de Botton`,
        title: `Status Anxiety`,
        chat: [
          `I appreciate the premise of the book`,
          `probably more than actually reading it`,
          `but, I think, reflecting on how status works in the world helps me let go of it more easily.`,
          `I'm team ego death btw`,
        ],
      }, {
        author: `Brathwaite`,
        title: `Challenges for Game Designers`,        
        chat: [
          `I like that this is focused on board games`,
          `and seeing the connection between all types of games..`,
          `I'd like to design a few games from this`,
        ],
      }, {
        author: `Jericho Brown`,
        title: `The Tradition`,
        chat: [
          `It's a beautiful book`,
          `[Foreday in the Morning](https://onbeing.org/poetry/foreday-in-the-morning/) is so, so good`,
          `and I love his duplex form, his appreciation for the line.`,
          `I've listened to a few podcasts/interviews he's given and really appreciate his voice`,
        ],
      }, {
        author: `Simone Browne`,
        title: `Dark Matters`,
        chat: [
          `I got this for a class`,
          `the way it delves into history is really powerful`,
          `it's a harrowing read`,
        ],
      }, {
        author: `Thomas Bulfinch`,
        title: `Bulfinch's Mythology: the Age of Fable`,
        chat: [
          `I found this in my ebook library`,
          `and remembered in high school when I'd go to the library during lunch to read through this`,
          `and that probably tells you everything you need to know about my high school experience`,
        ],
      }, {
        author: `Octavia Butler`,
        title: `Kindred`,
        chat: [
          `This really transcends a lot of what I think of sci-fi being`,
          `It’s crazy how well it shows the psychology of slavery`,
          `Like I felt helpless just reading the book…`,
        ],
      }, {
        author: `Octavia Butler`,
        title: `Parable of the Sower`,
        chat: [
          `This felt a little too close to home didn’t it?`,
          `It made me uncomfortable, but I don’t think that discomfort is bad…`,
          `The pacing kinda confused me because it felt like all bad things for awhile and then all good things for awhile`,
          `Maybe that’s an oversimplification though`,
          `I like how the answer was social bonds. Reminds me of some of the notes from Offill’s Weather’s obligatory note of hope..`,
        ],
      }, {
        author: `Italo Calvino`,
        title: `If on a winter's night a traveler`,
        chat: [
          `I love how Calvino will play with form and attempt to create something new`,
          `but it often doesn't hold my attention as well as language-focused books`,
          `so the structurer of this narrative is interesting, but at a certain point, you get it`,
          `and then I feel like it loses it's allure`,
        ],
      }, {
        author: `Italo Calvino`,
        title: `Invisible Cities`,
        chat: [
          `Is it funny that I like the Marco Polo sections more than the cities?`,
          `The one where he describes a traveler and the negative mirror is one of my favorite pages of writing ever`,
          `I'll look it up if you haven't read it..`,
        ],
      }, {
        author: `Julia Cameron`,
        title: `The Artist's Way`,
        chat: [
          `I feel like the core idea of this is getting into a practice of morning pages & taking yourself on dates`,
          `and both of these practices seem good.`,
          `It feels like there's a lot of value in just emptying your head ala morning pages...`,
        ],
      }, {
        title: `The Myth of Sisyphus`,
        author: `Albert Camus`,
        chat: [
          `I read The Stranger too, but I didn't really like it`,
          `it felt like he should've just written a philosophy book instead of a novel..`,
          `but I don't remember much from going through this except about it rationalizing suicide`,
        ],
      }, {
        author: `Anne Carson`,
        title: `Glass, Irony & God`,
        chat: [
          `It's so, so good`,
          `it feels like it balances scholarship and personal life so well.`,
          `it's like you're both learning and connecting on a very deep human level`,
        ],
      }, {
        author: `Anne Carson`,
        title: `Nox`,
        chat: [
          `I saw this book and thought it was soooooo cool that I had to have it`,
          `It changes what I think a book could be and, of course, has the merging of the personal and (ancient) history that Carson does so well..`,
          `This is what modernism should’ve been.`,
        ],
      }, {
        author: `Raymond Carver`,
        title: `Where I'm Calling From: Selected Stories`,
        chat: [
          `I really like Carver's stories`,
          `after you read one, it feels like you're left with it`,
          `it sticks to you in a way other stories don't.`,
          `I think some of that has to be his writing process`,
          `like I don't think he knows how they'll end and it feels like he just decides to end on a whim`,
        ],
      }, {
        author: ``,
        title: `Selected Poems and Prose of Paul Celan`,
        chat: [
          `The myth of Celan is interesting, so much sadness and loneliness..`,
          `I haven't delved deep enough into this to know what I think, but would love to hear what you do`,
        ],
      }, {
        author: `Francis Chan`,
        title: `Crazy Love`,
        chat: [
          `I appreciate the intensity of this`,
          `I do feel like Jesus was a revolutionary`,
          `but the church doesn't feel very revolutionary`,
          `and I feel like this gets at that`,
        ],
      }, {
        author: `Eileen Chang`,
        title: `Love in a Fallen City`,
        chat: [
          `My memory's not good, but I remember liking the tone`,
          `and the way it approached the whimsy of romance`,
        ],
      }, {
        author: `Eileen Chang`,
        title: `The Rice Sprout Song`,
        chat: [
          `I feel like I spent most of this book questioning the ideology it was coming from`,
          `There’s such a delicate balance between individualism and collectivism…`,
          `And sometimes we focus on divisions like that instead of looking at how power functions 🤔`,
        ],
      }, {
        author: `Victoria Chang`,
        title: `Obit`,
        chat: [
          `I really loved how much this felt like a book`,
          `Seeing images and themes carry throughout the book were really cool`,
          `And the book feels like a newspaper!!!`,
          `It matches the content really well`,
          `I liked the 3rd chapter the most.. it felt the most philosophical.. but all were necessary`,
          `Grief is hard and I think it lasts longer than we acknowledge.. I wish there was more room for these times we all go through`,
        ],
      }, {
        author: `Ted Chiang`,
        title: `Exhalation`,
        chat: [
          `I was really impressed by how thoughtful these stories are`,
          `Like all of them are really interesting`,
          `And there’s a lot more depth than I was expecting`,
          `It’s unlike other things I’ve read in a way that’s really striking`,
        ],
      }, {
        author: `GK Chesterton`,
        title: `The Ball and the Cross`,
        chat: [
          `I’ve been thinking about how stories will have characters represent ideas and this is such a clear example of it.. is it a good idea or not?`,
          `I’m not sure the characters ever transcend what they're representing even if he explored a lot of ideas`,
          `The writing is flows well and is witty though, it makes for a fun read`,
        ],
      }, {
        author: `Clayton Christensen`,
        title: `How Will You Measure Your Life?`,
        chat: [
          `I think the best 'life advice' is just an open-ended question`,
          `mostly because people don't actually want advice`,
          `and this one is interesting...`,
          `I'm just not sure most ways of measuring a life lead to a good life`,
          `some anecdotes are good: there is a slippery slope for our personal values`,
        ],
      }, {
        author: `James Clear`,
        title: `Atomic Habits`,
        chat: [
          `It's really straightforward`,
          `I think he found the right structure for explaining what he wanted to`,
        ],
      }, {
        author: `Lucille Clifton`,
        title: `how to carry water`,
        chat: [
          `I love her poems`,
          `It feels like poetry was just a companion to her life and I love how she writes about her life`,
          `There’s so many poems where she’s kind to herself or accepting of aging or just crazy resilient and it’s so good`,
        ],
      }, {
        author: `Annie Dillard`,
        title: `The Writing Life`,
        chat: [
          `I enjoyed this, the prose is really nice`,
          `and I think it reiterates a lot of things I've read about writing very succinctly`,
        ],
      }, {
        author: `Fyodor Dostoevsky`,
        title: `The Brother's Karamazov`,
        chat: [
          `I've been thinking about re-reading this`,
          `partly because I took long gaps while I read it`,
          `it defintely felt like Dosteovsky's magnum opus`,
          `all the characters felt like they had depth`,
        ],
      }, {
        author: `Fyodor Dostoevsky`,
        title: `Crime & Punishment`,
        chat: [
          `I actually think I got the most out of the sub-characters`,
          `like Ras's communist friend and his sister's suitor.`,
          `All of Dostoevsky's characters feel so intense, almost inhumanely so`,
        ],
      }, {
        author: `Fyodor Dostoevsky`,
        title: `White Nights`,
        chat: [
          `I got this because I heard it described as being about a super neckbeard, fedora-wearing white knight`,
          `and it totally is hahahah`,
        ],
      }, {
        author: `Peter F. Drucker`,
        title: `Managing Oneself`,
        chat: [
          `As far as giving life advice`,
          `it does offer a lot of good questions`,
          `the hard part is always answering the questions`,
        ],
      }, {
        author: `Ralph Waldo Emerson`,
        title: `Essays`,
        chat: [
          `I like Emerson's voice`,
          `I sometimes think about all the different threads that are America`,
          `right now, it feels like we're nothing but consumerism`,
          `but I feel like there was something else at some time and his essays make me think about that.`,
          `I wish I remember which essay it was he talked about going to church early to be alone`,
        ],
      }, {
        author: `Fumiko Enchi`,
        title: `Masks`,
        chat: [
          `It feels so ominous`,
          `like there's something lurking under the surface`,
          `you know it's there, but it isn't going to tell you...`,
          `it's a cool vibe`,
        ],
      }, {
        author: `F. Scott Fitzgerald`,
        title: `Tender is the Night`,
        chat: [
          `I don't really jive with Fitzgerald super well`,
          `his world feels very foreign to me in a way I'm not interested in`,
          `I think because of class..`,
          `this book starts slow, intentionally. It reminds me of Love in the Time of Cholera in that way`,
          `and once it picks up, it's a lot deeper, better`,
        ],
      }, {
        author: ``,
        title: `Flora: Inside the Secret World of Plants`,
        chat: [
          `I got this as a reference because I have dreams of making a flower arranging game`,
          `it's got some pretty pictures`,
        ],
      }, {
        author: `Ross Gay`,
        title: `Book of Delight`,
        chat: [
          `I really enjoyed these essays`,
          `I feel like he's really intune with himself`,
          `and I appreciate the freedom he gives himself to be himself`,
          `it makes it feel really affirming`,
        ],
      }, {
        author: `Ross Gay`,
        title: `catalog of unabashed gratitude`,
        chat: [
          `He’s one of my favorite poets`,
          `I love how he’ll start with the mundane or ordinary sadness/existential blah and then bring the poem to joy and delight`,
          `He holds both of those so, so well`,
          `And I love how much of his writing will include the natural world`,
          `It really makes me want to have a huge garden`,
        ],
      }, {
        author: `Moore & Gibbons`,
        title: `Watchmen`,
        chat: [
          `The first time I read this, I was blown away`,
          `the way it desconstructs superheroes and comics felt special`,
        ],
      }, {
        author: `Louise Glück`,
        title: `The Wild Iris`,
        chat: [
          `The language is so, so beautiful`,
          `and it feels like being in a garden at times.`,
          `I like how much life it gives and draws from flowers`,
        ],
      }, {
        author: `Bob Goff`,
        title: `Love Does`,
        chat: [
          `I appreciate the whimsy of this`,
          `and also how it brings faith into an everyday context`,
        ],
      }, {
        author: `Carlos Andrés Gómez`,
        title: `Hijito`,
        chat: [
          `I feel like there's a precision to the language here,`,
          `the poems all feel thoughtful and I feel rewarded trying to learn from them.`,
          `I like, too, how it looks at family and culture in a poetic way.. like, trying to understand or let be`,
        ],
      }, {
        author: `Ursula K Le Guin`,
        title: `The Dispossessed`,
        chat: [
          `I was so impressed with this book`,
          `I didn’t really take sci-fi seriously before, but Le Guin [defends it so well](http://theliterarylink.com/leguinintro.html)`,
          `I wish I could defend anything as well as she does..`,
          `but honestly this book changed the way I think about power and what’s possible in terms of societal groups of humans`,
          `I don’t think she was trying to make something so persuasive because I think you can read this and think the whole experiment on the moon is terrible`,
          `but I find it really interesting.. like I find some of her other ideas about utopia super interesting. Would love to talk to others about this article..`,
        ],
      }, {
        author: `Richard W. Hamming`,
        title: `The Art of Doing Science and Engineering`,
        chat: [
          `I haven't really dug into this, but I've watched the You and Your Research lecture`,
          `and the intro by Bret Victor is worth the price of admission, so good`,
        ],
      }, {
        author: `Ernest Hemingway`,
        title: `A Farewell to Arms`, 
        chat: [
          `I do appreciate Hemingway's terseness`,
          `I feel like this book lasts a little too long`,
          `but it had to continue to finish the plot`,
          `I think the section in the hotel room at night is the best stream of consciousness writing I've come across`,
          `I really like those couple paragraphs and the ways they go back and forth into thoughts`,
        ],
      }, {
        author: `Abraham Joshua Heschel`,
        title: `The Sabbath`, 
        chat: [
          `I’m starting to think I probably just wanna read the intros for non-fiction books`,
          `kinda like reading the abstract for papers…`,
          `but I really like the way this categorizes time and space`,
          `and I agree that we are so often focused on space`,
          `and I like trying to make space for time`,
        ],
      }, {
        title: `all about love`,
        author: `bell hooks`,
        chat: [
          `I started this, but never finished it`,
          `I've been meaning to get back to it with a better mindset for reading it`,
        ],
      }, {
        author: `Ivan Illich`,
        title: `Tools for Convivialty`,
        chat: [
          `I think about Illich's ideas a lot and what it'd mean to be convivial`,
          `it feels like the right thing to do, even if our economic systems don't allow for it.`,
          `I sometimes think the scale of our world is part of what makes it suck sometiimes`,
          `scale 'wins' a lot, but it's like we've outgrown 'humans', exist within groups that are too large.`,
        ],
      }, {
        author: `Kazuo Ishiguro`,
        title: `The Remains of the Day`,
        chat: [
          `So much of what's interesting about this is breaking down the character`,
          `it means that a lot of what's happening isn't super interesting`,
          `but thinking about the character and how he's been shaped is`,
        ],
      }, {
        author: `Kazuo Ishiguro`,
        title: `Never Let Me Go`,
        chat: [
          `What a heartbreaking book, eh?`,
          `I like how fiction is able to help show hope in those situations...`,
        ],
      }, {
        author: `Kobayashi Issa`,
        title: `575 The Haiku of Issa`,
        chat: [
          `This is a beautiful book`,
          `Most of the Issa poems I had seen before this were the very witty and sorta funny ones`,
          `but I’ve been really impressed by how many seem sincere and, still, very good`,
        ],
      }, {
        author: `James Joyce`,
        title: `Dubliners`,
        chat: [
          `The Dead is one of my favorite short stories`,
          `The window scene is so perfectly written`,
          `but it's interesting because I feel lke the dinner scene is the climax of the whole book`,
          `so you kinda need to read all of them to get the full appreciation for it`,
        ],
      }, {
        author: `James Joyce`,
        title: `The Portrait of the Artist as a Young Man`,
        chat: [
          `I learned so much about the ways the form of language shapes its meaning from this`,
          `it was eye-opening`,
          `I really think this helped me understand what language and writing can do`,
        ],
      }, {
        author: `Ilya Kaminsky`,
        title: `Deaf Republic`,
        chat: [
          `I love the way this handles its themes of language and silence and listening`,
          `[We Lived Happily During the War](https://www.poetryfoundation.org/poems/91413/we-lived-happily-during-the-war) is such a great poem, too`,
        ],
      }, {
        author: `Ed. Ilya Kaminsky and Katherine Towler`,
        title: `God in the House`,
        chat: [
          `I savored this book`,
          `Every interview had something compelling about it, despite how different everyone’s experience was`,
          `It was really powerful for me to both see how people live out lives of faith and with poetry`,
          `These relationships are important to me too, so many felt like kindred spirits`,
          `Would love to talk about some of the individual interviews..`
        ],
      }, {
        author: `Yasunari Kawabata`,
        title: `Snow Country`,
        chat: [
          `This book is so desolate`,
          `it's hard to get through because of it`,
          `but I think there's something moving in all that sorrow`,
        ],
      }, {
        author: `Thomas Keating`,
        title: `The Path of Centering Prayer`,
        chat: [
          `I like this approach to prayer`,
          `every time I start reading it, I find myself resonating with it`,
          `but I haven't been able to make it all the way through yet`,
        ],
      }, {
        author: `Gene Kim et al`,
        title: `The Phoenix Project`,
        chat: [
          `I feel like business books suck`,
          `they're too often about the particulars of a situation`,
          `and I think this runs into that to some extent`,
          `but it was nice to have it fictionalized at least`,
        ],
      }, {
        author: `Martin Luther King Jr`,
        title: `Where Do We Go From Here?`,
        chat: [
          `MLK’s writing is so powerful`,
          `I didn’t think UBI was in the conscious so long ago and it was cool to see it here`,
          `I appreciate how directly he speaks into his ideas about justice`,
          `I’m still thinking about his ideas on power..`,
          `I understand where he’s coming from, but I’m interested in ways power might be ‘less’`,
        ],
      }, {
        author: `Verlyn Klinkenborg`,
        title: `Several short sentences about writing`,
        chat: [
          `I’m really not about people being prescriptive with language`,
          `languages are living things and I’d like us to let them evolve`,
          `and the people who are prescriptive feel like they’re trying to exclude others`,
          `that’s typically why I don’t like books about how to write`,
          `but I appreciate how this tries to deconstruct some notions that schooling gives us about writing`,
          `and I like how poetic it is, how much it cares about language`,
        ],
      }, {
        author: `Enfield & Kockelman`,
        title: `Distributed Agency`,
        chat: [
          `I've thought a lot about agency. It feels so important int our lives`,
          `and I like how this looks at it from different contexts like how agency works in groups, etc`,
        ],
      }, {
        author: `Milan Kundera`,
        title: `The Unbearable Lightness of Being`,
        chat: [
          `Kundera feels really witty`,
          `The way he explains the charactters and situations arising and the philosophy behind all of it is really engaging`,
          `like, he's so playful in all of it and makes it really enjoyable.`,
          `I think this is a beautiful book.`,
        ],
      }, {
        author: `Milan Kundera`,
        title: `The Book of Laughter and Forgetting`,
        chat: [
          `I got this book because I thought it would be about how power and regimes try to control history through forced forgetting`,
          `But, as Kundera is, it was about so much more than that`,
          `the humanity of the characters and the stories shine through`,
          `Even if they’re living through moments of social change, it’s often their more base human motivations that rise up`,
          `I appreciate that`,
        ],
      }, {
        author: `Miwon Kwon`,
        title: `One Place After Another`,
        chat: [
          `It's about site-specific art and I got it mostly to flip through`,
          `I think it's cool`,
        ],
      }, {
        author: `Min Jin Lee`,
        title: `Pachinko`,
        chat: [
          `It's such a page-turner...`,
          `some of the pacing felt tough with how many hard things to handle happened close together`,
          `but once you're done and can take a step-back, it doesn't matter much`,
          `it's a really good & moving ride`,
          `I like how it handles the messiness of family`,
        ],
      }, {
        author: `Yi Lei`,
        title: `Pachinko`,
        chat: [
          `I like how visceral these poems can be. It feels like they’re so honest about her feelings in a very deep way`,
          `Like just read [this](https://twitter.com/ryapapap/status/1436178443930988572)`,
        ],
      }, {
        author: `Golan Levin and Tega Brain`,
        title: `Code as Creative Medium`,
        chat: [
          `I love the examples in this book, there’s a lot of artwork that I think is really interesting and compelling`,
          `I think I saw a tweet or something Golan made about projects like this always being ‘conservative’`,
          `like it provides a potential syllabus which, naturally, excludes other projects that could be included`,
          `and I thought that was interesting…`,
        ],
      }, {
        author: `CS Lewis`,
        title: `A Grief Observed`,
        chat: [
          `I think a lot about how much we don’t give space for grief or changes in our lives that take a lot to heal from`,
          `Lewis writes so directly into his feelings and I like the way he’s very honest about how it felt to be around people then and his relationship to faith`,
          `It was nice to see it evolve over time too, the way time does transform these things`,
        ],
      }, {
        author: `CS Lewis`,
        title: `Mere Christianity`,
        chat: [
          `CS Lewis is a good writer and this captures his wit really well`,
          `it's funny how the most basic examinations of faith are often the most powerful`,
          `I feel like it spoke to a lot of the 'core' doubts I fall into around faith`,
          `I enjoyed this book a lot`,
        ],
      }, {
        author: `CS Lewis`,
        title: `The Great Divorce`,
        chat: [
          `I feel like the central premise of this is good because we often do choose things we'd say we don't want...`,
          `we are creatures of comfort, I suppose...`,
          `but the examples seemed well chosen`,
        ],
      }, {
        author: `CS Lewis`,
        title: `The Screwtape Letters`,
        chat: [
          `I appreciated the ways he wove morality into this`,
          `it kinda feels like an excuse to do that`,
          `it's hard to know what he actually believes is reality in all of this`,
        ],
      }, {
        author: `CS Lewis`,
        title: `Reflections on the Psalms`,
        chat: [
          `I thought it was interesting how he tried to separate out Jewish and Christian theology`,
          `there was a part where he mentioned the value of Paul being an example of what a Christian life can look like`,
          `not what it has to look like`,
          `that stuck with me...`,
        ],
      }, {
        author: `Brennan Manning`,
        title: `Abba's Child`,
        chat: [
          `I like how centered this is on care.`,
          `I feel like we, as a society, do a bad job of providing care and sometimes it's hard to accept care`,
          `I want to imagine what it'd be like if we were better`,
        ],
      }, {
        author: `Steve Martin`,
        title: `Born Standing Up`,
        chat: [
          `I'm not really a fan of Steve Martin's`,
          `but this came with a recommendation and I enjoyed it`,
          `I like thinking about the craft in stand-up comedy`,
          `and this does a good job laying out what a life can look like and how the craft is part of it`,
        ],
      }, {
        author: `Cormac McCarthy`,
        title: `Blood Meridian`,
        chat: [
          `This was hard to read`,
          `so cold and cruel`,
          `I guess there's some value and reminding yourself that life like that is possible`,
        ],
      }, {
        author: `Cormac McCarthy`,
        title: `The Road`,
        chat: [
          `What a depressing read...`,
          `it's nice how it ended with a tiny bit of hope`,
          `but, man...`,
        ],
      }, {
        author: `Herman Melville`,
        title: `Moby Dick`,
        chat: [
          `It's a serious book, isn't it?`,
          `I really like [the quote by Melville about Emerson](http://people.virginia.edu/~sfr/enam3150/melvilleletters.html)...`,
        ],
      }, {
        author: `Donald Miller`,
        title: `Blue Like Jazz`,
        chat: [
          `I think the realities of living with faith today is complex`,
          `and I appreciate its attempts to let it be simple`,
          `I found it enjoyable`,
        ],
      }, {
        author: `Hayao Miyazaki`,
        title: `Starting Point`,
        chat: [
          `I appreciate how this goes into the craft of making`,
          `you can tell he takes his work seriously (maybe too seriously)`,
          `and I appreciate seeing how he came to it`,
        ],
      }, {
        author: `Toni Morrison`,
        title: `Jazz`,
        chat: [
          `I feel like she did exactly what she meant to do with this`,
          `She carries the rhythm of jazz with the writing really well`,
        ],
      }, {
        author: `Toni Morrison`,
        title: `Beloved`,
        chat: [
          `I feel like I often hear people say that Morrison's writing is about the everyday realities of living with trauma`,
          `and I can see that in this book`,
          `there's something really powerful in seeing someone survive while carrying such weight`,
          `I really like this book`,
        ],
      }, {
        author: `Haruki Murakami`,
        title: `Blind Willow, Sleeping Woman`,
        chat: [
          `A lot of these stories are honestly not very good`,
          `And that’s what I like about it`,
          `I’d guess they are very early in his career and you can see glimmers of what would later become good`,
          `I felt like I learned a lot from it`,
        ],
      }, {
        author: `Haruki Murakami`,
        title: `Colorless Tsukuru...`,
        chat: [
          `I think this is my favorite Murakami novel. A friend shared this quote from Murakami:`,
          `'There are three types of emotional wounds: those that heal quickly, those that take a long time to heal, and those that remain with you until you die. I think one of the major roles of fiction is to explore as deeply and in as much detail as possible the wounds that remain.'`,
          `I feel like this book does that well.. the way it processes the past and looks at how it scars us and how we scar each other..`,
        ],
      }, {
        author: `Haruki Murakami`,
        title: `Kafka on the Shore`,
        chat: [
          `A lot of times I feel like in Murakami novels the main character is too passive`,
          `it's like the story happens to them`,
          `and I feel like this book doesn't which makes it good`,
          `I think it's the best book to start with..`,
        ],
      }, {
        author: `Haruki Murakami`,
        title: `Men Without Women`,
        chat: [
          `What sad stories...`,
          `I feel like it helped me understand the supernatural aspects of Murakami's writing`,
          `as, like.. parts of their subconscious, which helped me understand his work a lot`,
        ],
      }, {
        author: `Haruki Murakami`,
        title: `Norwegian Wood`,
        chat: [
          `I tried to re-read this recently, but it put me into too much of a funk`,
          `the sadness of this feels too real..`,
        ],
      }, {
        author: `Haruki Murakami`,
        title: `The Wind-Up Bird Chronicle`,
        chat: [
          `I think this is the best ‘long’ novel I’ve read from him`,
          `I still like his short stories more, but it feels the most complete`,
          `The characters are the most interesting and you kinda feel ok (maybe safe?) going into that dreamlike daze he brings you to`,
        ],
      }, {
        author: `Haruki Murakami`,
        title: `1Q84`,
        chat: [
          `I don't like Murakami's long novels as much as his shorter ones`,
          `it feels like they lack focus in a way..`,
          `but whenever I haven't read a book for awhile, I'll pick up something by Murakami`,
          `because I know I'll be easy enough to get into and they still work for that..`,
        ],
      }, {
        author: `Gabriel García Márquez`,
        title: `One Hundred Years of Solitude`,
        chat: [
          `I think what I thought the most about re: this book is about how the world tends towards entropy..`,
          `the story is told so well, I remember feeling the greatness of it while reading it..`,
          `when I was reading it, I was thinking about the blandness of the culture I come from and how vibrant Márquez presents his culture..`,
        ],
      }, {
        author: `Gabriel García Márquez`,
        title: `Love in the Time of Cholera`,
        chat: [
          `It's funny how different the first and second parts are`,
          `and how necessary the first part is after the fact`,
          `but the magic of the second part makes this so good`,
        ],
      }, {
        author: `Vladimir Nabokov`,
        title: `Pale Fire`,
        chat: [
          `I feel Nabokov is just one of those dudes who could write`,
          `I wasn't captured by the story, but the way he uses language is so precise`,
          `I'd like to read something else, but not sure what`,
          `I listened to an audiobook of Lolita long ago...`,
        ],
      }, {
        author: `VS Naipaul`,
        title: `Miguel Stret`,
        chat: [
          `This book feels so lively`,
          `like, I really feel like I'm in the community.`,
          `I got it because of [this article](https://granta.com/editing-vidia/) which is so, so good.`,
          `It talks a bit about his flaws as a person and I think you notice that in the writing too. But it feels alive in that, tied to its culture.`,
        ],
      }, {
        author: `Lesslie Newbigin`,
        title: `The Gospel in a Pluralist Society`,
        chat: [
          `I haven't really cracked this open`,
          `but I like the idea of it and feel like it'd be nice to better contexualize Christianity for myself`,
        ],
      }, {
        author: `Henri Nouwen`,
        title: `Life of the Beloved`,
        chat: [
          `This is my favorite book by Nouwen. It's crazy how comforting it is.`,
          `I think it gets at how important it is to have reminders of very core aspects of faith.`,
          `There's so much value in trying to get at the very basic tenets you hold...`,
        ],
      }, {
        author: `Henri Nouwen`,
        title: `Making All Things New`,
        chat: [
          `Nouwen writes so simply and directly...`,
          `I really appreciate that and I think it makes it very digestable`,
        ],
      }, {
        author: `Jenny Offill`,
        title: `Weather`,
        chat: [
          `The writing in this is so good`,
          `I feel like it perfectly captures the pessimism of being present in our time is`,
          `and it ends with this beautiful website`,
          `part of me wonders if it could've brought in some of the hope from that site`,
          `but I think that's overlooking what the writing process is like...`,
        ],
      }, {
        author: `Yoko Ogawa`,
        title: `The Memory Police`,
        chat: [
          `The narrative is sort of listless (in the best way possible)`,
          `I think it does a good job of looking at what modern life can feel like`,
          `I like how it wrote into that`,
        ],
      }, {
        author: `Mary Oliver`,
        title: `A Poetry Handbook`,
        chat: [
          `I really like the prose in this, it's really poetic`,
          `I appreciate the lessons in this book a lot`,
          `I got a book of Mary Oliver's poems because of it`,
          `Sometimes it's too cheesy (which is saying a lot: I'm pretty cheesy)`,
          `but when it isn't, It's really nice`,
        ],
      }, {
        author: `Mary Oliver`,
        title: `Devotions`,
        chat: [
          `I feel like I have to be in the right mood for her poems, but when I am they really hit`,
          `I appreciate how much she focuses on listening`,
          `and how she tries to speak so directly`,
        ],
      }, {
        author: `Bayles & Orland`,
        title: `Art & Fear`,
        chat: [
          `I wasn't blown away by this book`,
          `but I did appreciate what it was trying to do`,
          `and I think even knowing it exists helps with that`,
          `it's something I'll probably return to from time to time`,
        ],
      }, {
        author: `Ruth Ozeki`,
        title: `A Tale for the Time Being`,
        chat: [
          `I loved how this weaved together its different narratives and themes.`,
          `A lot of times I feel like one of the narratives does most of the lifting, but I enjoyed both sides.`,
          `She did a really good job of sharing ideas (like the Buddhist ideas) in the narrative without it feeling off.`,
        ],
      }, {
        author: `Ruth Ozeki`,
        title: ` My Year of Meat`,
        chat: [
          `I feel like these social cause books are tough to read`,
          `but this one was pretty funny. I love the play on ‘media is the message’ -> ‘meat is the message’`,
          `And that she is grappling with trying to find something we could do in light of these big problems`,
          `I remember thinking about the pacing of the episodes as ones that were and weren’t in control of…`,
          `and there were definitely some plot lines I was worried about where they were going to go haha`,
          `but she tied the book up very well`,
        ],
      }, {
        author: `Dunn & Parbury`,
        title: `3d Math Primer for Graphics and Game Development`,
        chat: [
          `I got this as a reference because I want to be better at math`,
          `I'm still not sure the best way to learn on my own`,
          `Honestly, a lot of the time I just hope the environment I'm in responds fast enough that I can change numbers until they work`,
        ],
      }, {
        title: `Crucial Conversations`,
        author: `Patterson et al`,
        chat: [
          `I mean, it's a soft-skills business book`,
          `but I appreciate how it takes language serously`,
        ],
      }, { 
        author: `Gregory Pardlo`,
        title: `Digest`,
        chat: [
          `This poetry feels really intellectual`,
          `beyond me a lot of the times`,
          `but [Wishing Well](http://pbqmag.org/greg-pardlo-wishing-well/) is so, so good`,
          `it feels transformative in all the best ways`,
        ],
      }, {
        author: `Marcel Proust`,
        title: `Swann's Way`,
        chat: [
          `I've tried to make my way through In Search of Lost Time a number of times`,
          `I think the furthest I've got is volume 3`,
          `some day I'll get back to it`,
          `it takes a lot to bring yourself into that pacing`,
          `but it feels like you're rewarded every so often with a story or setting that sticks with you`,
        ],
      }, {
        author: ``,
        title: `R.S. Thomas: Everyman Poetry`,
        chat: [
          `I got this, in part, because of [this article](https://www.plough.com/en/topics/culture/poetry/turn-aside)`,
          `which I think does a really good job of capturing what the poetry is about`,
        ],
      }, {
        author: `Rainer Maria Rilke`,
        title: `Letters to a Young Poet`,
        chat: [
          `I love how much vitality Rilke’s writing has in this`,
          `It really doesn’t let up.. and he has such high ideals for life and art`,
          `It's very inspiring`,
        ],        
      }, {
        author: `Rainer Maria Rilke`,
        title: `Rilke's Book of Hours: Love Poems to God`,
        chat: [
          `I love how spiritual Rilke is in his writing`,
          `I've been thinking about how hard it is to write about big truths..`,
          `even when you know them, it's hard to find form for the words and I love how Rilke just goes right for it`,
        ],        
      }, {
        author: `Hidetoshi & Rothman`,
        title: `Sacred Mathematics: Japanese Temple Geometry`,
        chat: [
          `I think it's cool seeing math from the perspective of another culture...`,
        ],
      }, {
        author: `Cynthia Rylant`,
        title: `God Went to Beauty School`,
        chat: [
          `Reminds me of a Wendell Berry linem, 'There are no unsacred places; there are only sacred places and desecrated places.'`,
          `I think the attempt to point out how the everyday is part of the sacred is really enjoyable, pleasant, playful.. nice`,
        ],
      }, {
        author: `Seneca`,
        title: `On the Shortness of Life`,
        chat: [
          `It's close to the philosophy I dig`,
          `but I think he's trying to bring too much reason into everything`,
        ],
      }, {
        author: `Shakespeare`,
        title: `King Lear`,
        chat: [
          `I've come back to this a few times`,
          `I really like Orwell's [Lear, Tolstoy and the Fool](https://www.orwell.ru/library/essays/lear/english/e_ltf)`,
          `there's something harsh in Shakespeare, but I think it's true to life`,
          `and I appreciate the warning that is in King Lear, it still feels relevant`,
        ],
      }, {
        author: `Grünbaum & Shephard`,
        title: `Tilings & Patterns`,
        chat: [
          `I got this as a reference book..`,
          `it does indeed have tilings and patterns`,
        ],
      }, {
        author: `Dai Sijie`,
        title: `Balzac and the Little Chinese Seamstress`,
        chat: [
          `This was a fun read`,
          `At times, it was too light for the subject at hand`,
          `but sometimes that's how we approach life, isn't it?`,
        ],
      }, {
        author: `John Steinbeck`,
        title: `East of Eden`,
        chat: [
          `This felt so much like his magnum opus`,
          `I liked it a lot better than the Steinbeck I read in high school`,
          `I think it did a good job of making sure the story wasn't pure analogy with its inspirations`,
          `it gave it a depth that's really nice`,
        ],
      }, {
        author: `Shunryu Suzuki`,
        title: `Zen Mind, Begiinner's Mind`,
        chat: [
          `I think back to the premise from time to time:`,
          `that it's nice to not have conclusions`,
          `it feels true`,
        ],
      }, {
        author: `Natsume Sōseki`,
        title: `Kokoro`,
        chat: [
          `In some ways, this felt culturally inaccessible to me`,
          `but I appreciated the tensions that were held in it`,
        ],
      }, {
        author: `Natsume Soseki`,
        title: `Sanshiro`,
        chat: [
          `The first chapter of this is one of the funniest things I've ever read`,
          `There's a way in which I resonate with this..`,
          `I don't know if Soseki also grew up in a small town before coming to a city`,
          `but it feels like it.. and it captures the awkwardness of growing up in a way`,
        ],
      }, {
        author: ``,
        title: `Tao Te Ching`,
        chat: [
          `I like how ungraspable The Way is`,
          `I try to understand a lot of the world with language`,
          `and the way it shows it's impossible to understand everything feels important`,
        ],
      }, {
        author: ``,
        title: `The Art of the Short Story`,
        chat: [
          `I know this is just a textbook`,
          `but I like a lot of the stories`,
          `Cathedral (Carver), Sonny's Blues (Baldwin), A Very Old Man With Enormous Wings (Márquez), Omegles (Le Guin) etc etc`,
          `I like them...`,
        ],
      }, {
        author: ``,
        title: `The Ink Dark Moon: Love Poems by Ono no Komachi and Izumi Shikibu`,
        chat: [
          `I think there's something really powerful in seeing people from long ago feeling like we feel`,
          `and I think these poems touch on that`,
        ],
      }, {
        author: ``,
        title: `The Penguin Book of Japanese Short Stories`,
        chat: [
          `A lot of these stories are wild`,
          `they just go really hard`,
          `but I enjoyed a lot of them`,
          `Bee Honey by Banana Yoshimoto is one of my favorite stories`,
        ],
      }, {
        author: `Madeleine Thien`,
        title: `Do Not Say We Have Nothing`,
        chat: [
          `I loved the way this book tied together all of its themes`,
          `it's this beautiful mix of history-- the way stories intersect with the past, the meaning of art (and also life), silence and speaking-- what we must say..`,
          `the backdrop of the cultural revolution was really powerful too. The way she made and told stories was really well done`,
        ],
      }, {
        author: `Howard Thurman`,
        title: `Jesus and the Disinherited`,
        chat: [
          `All of the themes/chapters of this book are really well thought of.`,
          `The interpretation of liberation theology feels important.. like, it's a framing that makes sense to me.`,
          `The part where he talks about reading the Bible with his grandmother and never reading Paul has also stuck with me`,
        ],
      }, {
        author: `Leo Tolstoy`,
        title: `War and Peace`,
        chat: [
          `I've been thinking about re-reading this because it feels like it's been long enough I could bring a different perspective`,
          `I enjoyed the meshing of philosophy, history, and the culture of it`,
          `there's some scenes that are still clear in my memory, like the hugging over money`,
          `and how it contextualized 'people of the day'- politicians I've never heard of`,
        ],
      }, {
        author: `Leo Tolstoy`,
        title: `Anna Karenina`,
        chat: [
          `I find it interesting how Tolstoy thought of this as a failed novel`,
          `I think it probably means he had too many conclusions about what the book would be`,
          `but it's interesting he can set aside those things to do the actual writing.`,
          `I enjoyed following the symbols and themes to their conclusion`,
        ],
      }, {
        author: `Leo Tolstoy`,
        title: `The Death of Ivan Ilyich`,
        chat: [
          `I feel like Tolstoy's shorter writings get a lot more pointed`,
          `The Death of Ivan Ilyich does a good job of focusing mortality, I think`,
          `one thing I wish it captured better is how easy I think it is to slide into an Ilyich-like life`,
          `some of that's because of how our society is setup though`,
          `and everyone has to discover their own alternatives`,
          `I also like the short stories How Much Land Does a Man Need? and Master and Man`,
        ],
      }, {
        author: `Leo Tolstoy`,
        title: `A Calendar of Wisdom`,
        chat: [
          `I've read a bit of Tolstoy's later work.. I like this one because it's just accumulating quotes mostly`,
          `the quotes are good and wholesome and moral like you'd expect`,
        ],
      }, {
        author: `Pádraig Ó Tuama`,
        title: `Daily Prayers`,
        chat: [
          `I got this signed actually`,
          `he asked me what I did and I said I wrote software`,
          `and he signed it for all our writing :')`,
        ],
      }, {
        author: `Pádraig Ó Tuama`,
        title: `In the Shelter`,
        chat: [
          `I love how much this is willing to sit with the complexity of faith`,
          `there's something nice about someone being honest about struggling with faith`,
          `and what it looks like to find a deeper, more secure faith`,
          `I liked the early chapters a lot more than the later ones..`,
        ],
      }, {
        author: `Virgil`,
        title: `The Aeneid`,
        chat: [
          `I first read this in a high school Latin class`,
          `everyone else seemed to be complaining about it`,
          `but I *loved* it`,
        ],
      }, {
        author: `Kurt Vonnegut`,
        title: `If This Isn't Nice, What Is?`,
        chat: [
          `I really like the form of commencement speeches`,
          `this is obviously repetitive since they're all by one person`,
          `but I appreciate his wit and some of his themes`,
          `a lot of it is about community and kindness, really`,
        ],
      }, {
        author: `Ocean Vuong`,
        title: `On Earth We're Briefly Gorgeous`,
        chat: [
          `I love prose from poets and this definitely fits the bill`,
          `It’s interesting reading a coming of age novel that’s like from when I was growing up`,
          `It kinda reminded me of how I felt growing up (not good) and I was uncomfortable because of that`,
          `I’ve also seen media recently that’s not from/for cis white men and I feel like I’ve learned a lot by how it’s made me feel`,
        ],
      }, {
        author: ``,
        title: `Watch for the Light`,
        chat: [
          `This is a set of readings for advent`,
          `I've struggled to get into devotional readings`,
          `but I really enjoyed all of these..`,
          `the attention they pay to the waiting is nice`,
        ],
      }, {
        author: `Alan Watts`,
        title: `The Wisdom of Insecurity`,
        chat: [
          `I like the perspectives in this and believe it's true that we search too much for security`,
          `I've thought about getting a tattoo with wuwei (pinyin) because of it..`,
          `I really like this quote about faith in the book:`,
          `We must here make a clear distinction between belief and faith, because, in general practice, belief has come to mean a state of mind which is almost the opposite of faith. Belief, as I use the word here, is the insistence that the truth is what one would “lief” or wish it to be. The believer will open his mind to the truth on the condition that it fits in with his preconceived ideas and wishes. Faith, on the other hand, is an unreserved opening of the mind to the truth, whatever it may turn out to be. Faith has no preconceptions; it is a plunge into the unknown. Belief clings, but faith lets go. In this sense of the word, faith is the essential virtue of science, and likewise of any religion that is not self-deception`,
        ],
      }, {
        author: `Walt Whitman`,
        title: `Leaves of Grass`,
        chat: [
          `His long lines are so delightful...`,
          `I haven't spent near enough time with this`,
        ],
      }, {
        author: `Jackie Wong`,
        title: `Carceral Capitalism`,
        chat: [
          `A lot of what I read in here is heartbreaking`,
          `it does a really good job at looking at structural issues`,
        ],
      }, {
        author: `James Wright`,
        title: `Collected Poems`,
        chat: [
          `I haven’t gotten too far into this, but I like its connection to the natural world and Minnesota`,
          `like [this poem](https://www.poetryfoundation.org/poems/47734/lying-in-a-hammock-at-william-duffys-farm-in-pine-island-minnesota)`,
        ],
      }, {
        author: ``,
        title: `Worlds of Modern Chinese Fiction`,
        chat: [
          `I got this because it has The General's Monument by Zhang Dachun in it`,
          `that's one of my favorite short stories ever`,
          `the way it weaves history and generational change together is really beautiful`,
        ],
      }, {
        author: `Can Xueqin`,
        title: `The Story of the Stone: Volume 1`,
        chat: [
          `real talk: I got this cuz the SF Opera had a show about Dream of the Red Chamber`,
          `and I wasn't able to get into this`,
        ],
      }, {
        author: `Mo Yan`,
        title: `Red Sorghum`,
        chat: [
          `The tone of this is so harsh.. it feels unique in that way`,
        ],
      }, {
        author: `Adam Zagajewski`,
        title: `Without End`,
        chat: [
          `I got this after he passed. I saw some of his poems around power and politics and resonated`,
          `like I think back to [this one](https://maddingsouls.livejournal.com/84658.html) and also [this one](https://books.google.com/books?id=IpfODAAAQBAJ&pg=PA75&lpg=PA75#v=onepage&q&f=false) a lot`,
          `I like his poems about exile and homeland too, it’s thoughtful.`, 
        ],
      },
    ]
  },
  /*
  {
    category: 'Articles',
    chat: [
      `I'm not sure exactly what to put here because I'm not sure how to define an article`,
      `I feel like sometimes people can make their identity tied up in things they consume & I don't want to do that`,
      `Sometimes the things I think the most about are the things that I disagree with`,
      `For awhile, I'd put things I read online in Pocket`,
    ],
    items: [
      {
        title: 'TODO',
        chat: [
          `sorry, I haven't gotten around to doing this yet`,
        ]
      },
    ]
  },
  {
    category: 'Podcasts',
    chat: [
      `I don't feel like podcasts fit cleanly into my life, so I don't listen to them often`,
      `I actually started listening a bit more in 2020 while driving around or doing chores`,
    ],
    items: [

    ]
  },
  {
    category: 'Movies',
    chat: [
      `I'm really not a big movie person`,
      `When I was younger, and trying to be cool, I'd watch avante-garde arthouse flicks`,
      `nowadays I'll go through the oscar noms for anything that looks good and that's about it`,
    ],
    items: [

    ]
  },
  {
    category: 'Video Games',
    chat: [],
    items: [

    ]
  }
  */
];

export default books;
