/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useEffect, useRef, useState } from 'react'
import { animated } from 'react-spring/three';
import { useGLTF } from '@react-three/drei/useGLTF'
import { Color } from 'three';

export default function Model({spring, ...props}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Coffee/coffee_cup.gltf')

  const [drunk, setDrunk] = useState(1);
  const [drinking, setDrinking] = useState(false);

  useEffect(() => {
    const sip = () => {
      setDrunk(d => Math.max(0.1, d - 0.01));
    }

    let interval;
    if (drinking) {
      interval = setInterval(sip, 100);
    }

    return () => {
      clearInterval(interval);
    }
  }, [drinking]);

  if (!props.drink) {
    return null;
  } else if (props.drink === 'green') {
    materials.lambert3SG.color = new Color('rgb(42, 69, 4)');
  } else if (props.drink === 'black') {
    materials.lambert3SG.color = new Color('rgb(82, 33, 16)');
  } // else if (props.drink === 'coffee') {
    // keep original material..
    // }

  nodes['buffer-0-mesh-0'].geometry.computeBoundingBox();
  nodes['buffer-0-mesh-0'].geometry.center();

  // this feels... weird.
  // in my head, i should be able to just scale the Y down until all the liquid is gone
  // but this doesn't work ??
  // so, also scaling x and z. I doubt this 'stays in the cup like this
  // is a very 'oh well' feeling . . .
  const onClick = (e) => { 
    setDrunk(d => Math.max(0.1, d - 0.05));
  };

  const onPointerUp = () => {
    props.setLookDisabled(false);
    setDrinking(false);
  }

  const onPointerDown = () => {
    props.setLookDisabled(true);
    setDrinking(true);
  }

  const drinkScale = [drunk,drunk,drunk];

  materials.lambert3SG.transparent = true;
  materials.lambert2SG.transparent = true;


  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0.07, 0.75, 0.01]}>
        <group position={[-0.07, -0.75, -0.01]}>
          <group position={[0, 1.2 * drunk, 0]} scale={drinkScale}>
            <animated.mesh 
              {...spring} 
              material={materials.lambert3SG} 
              geometry={nodes['buffer-0-mesh-0'].geometry} 
              onClick={onClick}
              onPointerDown={onPointerDown}
              onPointerUp={onPointerUp}
              onPointerOut={onPointerUp}
              onPointerLeave={onPointerUp}
            />
          </group>
          <animated.mesh {...spring} material={materials.lambert2SG} geometry={nodes['buffer-0-mesh-0_1'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Coffee/coffee_cup.gltf')
