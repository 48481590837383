/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { animated } from 'react-spring/three';
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model({spring, ...props}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Phone/Phone.gltf')
  materials.Mat.transparent = true;
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.31, 15.09, -3.46]}>
        <group position={[0.31, -15.09, 3.46]}>
          <animated.mesh {...spring} material={materials.Mat} geometry={nodes['node-0'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Phone/Phone.gltf')
