/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'
import { PerspectiveCamera } from '@react-three/drei/PerspectiveCamera'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/CardboardBox/model.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.4, 0.61, -0.36]}>
        <group position={[0.4, -0.61, 0.36]}>
          <mesh material={materials.BlocksPaper} geometry={nodes['node_MeshObject-317449216-PolyPaper21'].geometry} />
        </group>
      </group>
      <PerspectiveCamera
        makeDefault={false}
        far={2000000}
        near={0.1}
        fov={45}
        position={[1.81, 2.16, 0.9]}
        rotation={[-0.89, 0.84, 0.74]}>
        <directionalLight intensity={0.25} decay={2} color="#ffeedd" position={[-1.08, 0.54, 0.54]} />
      </PerspectiveCamera>
      <directionalLight
        intensity={0.32}
        decay={2}
        color="#ffeedd"
        position={[-1.52, 2.8, -1.58]}
        rotation={[-2.03, -0.42, -0.67]}
        scale={[0, 0, 1.09]}
      />
    </group>
  )
}

useGLTF.preload('/models/CardboardBox/model.gltf')
