/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Tree/tree01.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-21.77, 314.22, -9.63]}>
        <group position={[21.77, -314.22, 9.63]}>
          <mesh material={materials.Mat} geometry={nodes['node-0'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Tree/tree01.gltf')
