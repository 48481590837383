import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Switch, Route, useHistory, useRouteMatch } from "react-router-dom";
import { DefaultLoadingManager } from 'three';
import { Html, Stars, PerspectiveCamera } from '@react-three/drei';
import { A11y } from '@react-three/a11y';
import { useSpring, animated } from 'react-spring/three';
import { HtmlChatWrapper as Chat } from '../components/chat';
import Chats, {CHAT_NAME} from '../chats/inside';
import BookshelfModal from '../components/bookshelf';
import Credits from '../components/credits';
import Contacts from '../components/contact';
import OnionMan from '../components/onionMan';
import { useDragToLook } from '../utils';

import Bookshelf from '../models/Bookshelf';

import Sofa from '../models/Sofa';

import Armchair from '../models/Armchair';
import Coffee from '../models/Coffee';
import TableSide from '../models/TableSide';

import Table from '../models/Table';
import Typewriter from '../models/Typewriter';
import Notebook from '../models/Book';
import Phone from '../models/Phone';
import Chair from '../models/Chair';

import WallPainting from '../models/WallPainting';
import Houseplant from '../models/Houseplant';
import Window from '../models/Window';

import Door from '../models/Door6';

function Room(props) {
  const {width, length, height, spring} = props;

  const wallColor = "rgb(213, 187, 158)";
  const floorColor = "rgb(179, 83, 54)";
  const ceilingColor = "rgb(245, 242, 237)";

  return (<>
    <group position={[0, 0, -length/2]}>
      <animated.mesh {...spring}>
        <planeBufferGeometry attach="geometry" args={[width, height]} />
        <meshBasicMaterial alphaTest={0.1} transparent attach="material" color={wallColor} roughness={0.1} metalness={1} />
      </animated.mesh>
    </group>
    <group position={[0, 0, length/2]} rotation={[Math.PI,0,0]}>  
      <animated.mesh {...spring}>
        <planeBufferGeometry attach="geometry" args={[width, height]} />
        <meshBasicMaterial alphaTest={0.1} transparent attach="material" color={wallColor} roughness={0.1} metalness={1} />
      </animated.mesh>
    </group>
    <group position={[-width/2, 0, 0]} rotation={[0,Math.PI/2,0]}>
      <animated.mesh {...spring}>
        <planeBufferGeometry attach="geometry" args={[length, height]} />
        <meshBasicMaterial alphaTest={0.1} transparent attach="material" color={wallColor} roughness={0.1} metalness={1} />
      </animated.mesh>
    </group>
    <group position={[width/2, 0, 0]} rotation={[0,-Math.PI/2,0]}>  
      <animated.mesh {...spring}>
        <planeBufferGeometry attach="geometry" args={[length, height]} />
        <meshBasicMaterial alphaTest={0.1} transparent attach="material" color={wallColor} roughness={0.1} metalness={1} />
      </animated.mesh>
    </group>

    <group position={[0, height/2, 0]} rotation={[Math.PI/2,0,0]}>  
      <animated.mesh {...spring}>
        <planeBufferGeometry attach="geometry" args={[width, length]} />
        <meshBasicMaterial alphaTest={0.1} transparent attach="material" color={ceilingColor} roughness={0.1} metalness={1} />
      </animated.mesh>
    </group>

    <group position={[0, -height/2, 0]} rotation={[-Math.PI/2,0,0]}>  
      <animated.mesh {...spring}>
        <planeBufferGeometry attach="geometry" args={[width, length]} />
        <meshBasicMaterial alphaTest={0.1} transparent attach="material" color={floorColor} roughness={0.1} metalness={1} />
      </animated.mesh>
    </group>

  </>);
}

function LivingRoom() {
  const [drink, setDrink] = useState();
  const [lookDisabled, setLookDisabled] = useState(false);
  const [currentChat, setCurrentChat] = useState();
  const [onionEyes, setOnionEye] = useState([0,0,0]);

  const history = useHistory();
  const { path } = useRouteMatch();

  const isBaseRoom = history.location.pathname === path;

  const isLoaded = useRef(false);
  DefaultLoadingManager.onLoad = () => {    
    if (isBaseRoom) {
      setCurrentChat(CHAT_NAME.intro);
      // this is #bad, but I don't want stars 
      // until after all the other stuff is drawn...
      setTimeout(() => isLoaded.current = true, 1000);
    }
  }

  const resetChat = () => {
    setCurrentChat(null);
  }
  const showModal = (url) => {
    resetChat();
    history.replace(`${path}/${url}`);
  };
  const hideModal = () => {
    history.replace(`${path}`);
  }

  useEffect(() => {
    const onEscape = (event) => {
      if(event.key === 'Escape') {
        setCurrentChat(null);
      }
    };

    window.addEventListener("keydown", onEscape, false);
    return () => {
      window.removeEventListener("keydown", onEscape, false);
    }
  }, [])

  const dragCancels = useDragToLook([-0.5, 1.5], [-0.3, 0.35], !isBaseRoom || lookDisabled);

  const [fade, setFade] = useState(false);
  const springProps = useSpring({
    'material-opacity': fade ? 0 : 1,
    config: { duration: 3000 },
  });

  return (
    <>
      <Suspense fallback={null}>

        <group position={[0, 0, 2]}>  
          <Room 
            width={18}
            length={18}
            height={8}
            spring={springProps}
          />
        </group>

        <group position={[-8, -4, -6]} rotation={[0,Math.PI/2+0.2,0]}>
          <A11y role="button" description="A bookshelf">  
            <Bookshelf 
              spring={springProps}
              onClick={() => {
                if (dragCancels() && !fade) {
                  setOnionEye([0,-0.1,-0.1]); 
                  setCurrentChat(CHAT_NAME.bookshelf);
                }
              }} 
            />
          </A11y>
        </group>
        <group position={[-1, 1, 0.2]}>
          {currentChat === CHAT_NAME.bookshelf &&
          <Chat onDestroyed={resetChat} chats={Chats.bookshelf(() => showModal(`bookshelf`))} />}
        </group>

        <group position={[1.5, -4, -5]} rotation={[0,-Math.PI/2,0]} scale={[0.3,0.3,0.3]}>  
          <Sofa spring={springProps} />
        </group>
        <group position={[-1.6, -0.2, 0.2]} rotation={[0,0.5,0]}>
          <A11y role="button" description="A giant humanoid onion">
            <OnionMan 
              eyeRotation={onionEyes}
              onClick={() => {
                if (dragCancels() && !fade) {
                  setOnionEye([0,0,0]);
                  setCurrentChat(CHAT_NAME.onionMan);
                }
              }} 
            />
          </A11y>
          <group position={[4, 1, 0.2]}>
            {currentChat === CHAT_NAME.intro &&
            <Chat onDestroyed={resetChat} chats={Chats.intro(setDrink)} />}
            {currentChat === CHAT_NAME.onionMan &&
            <Chat onDestroyed={resetChat} chats={Chats.onionMan(() => setFade(true), () => setFade(false))} />}
          </group>
        </group>

        <group position={[7.5, -4, -5]} rotation={[0,0,0]} scale={[0.5,0.5,0.5]}>  
          <A11y role="button" description="A houseplant">
            <Houseplant 
              spring={springProps}
              onClick={() => {
                if (dragCancels() && !fade) {
                  setOnionEye([0,0.3,-0.1]);
                  setCurrentChat(CHAT_NAME.plant);
                }
              }}
            />
          </A11y>
        </group>
        <group position={[4, 0, 0.2]}>
          {currentChat === CHAT_NAME.plant &&
          <Chat onDestroyed={resetChat} chats={Chats.plant()} />}
        </group>

        <group position={[-9, -4, 6]} rotation={[-Math.PI/2,0,-Math.PI/2]} scale={[0.012,0.012,0.012]}>  
          <A11y role="button" description="A door">
            <Door 
              spring={springProps}
              onClick={() => {
                if (dragCancels() && !fade) {
                  setOnionEye([0,-0.3,0]);
                  setCurrentChat(CHAT_NAME.closet);
                }
              }}
            />
          </A11y>
        </group>
        <group position={[-50, 8, 5]}>
          {currentChat === CHAT_NAME.closet &&
          <Chat onDestroyed={resetChat} chats={Chats.closet()} />}
        </group>
        
        <group position={[5, -4, 4.85]} rotation={[0,Math.PI/2+0.6,0]} scale={[0.4,0.4,0.4]}>  
          <Armchair />
        </group>
        <group position={[5.8, -2.3, 2.4]} rotation={[0,-0.9,0]} scale={[0.1,0.1,0.1]}>  
          <A11y role="button" description="A teacup">
            <Coffee drink={drink} setLookDisabled={setLookDisabled} spring={springProps} />
          </A11y>
        </group>
        <group position={[7.5, -3.9, 1.25]} rotation={[0,Math.PI/2,0]} scale={[1.75,1.75,1.75]}>  
          <TableSide spring={springProps} />
        </group>
        
        <group position={[-8, -0.7, 1.5]} rotation={[0,Math.PI/2,0]}>
          <group position={[0, -1.8, -1]} rotation={[0,0,0]} scale={[0.01,0.012,0.01]}>  
            <Table spring={springProps} />
          </group>
          <group position={[1, -1.35, 0.75]} rotation={[0,Math.PI,0]} scale={[0.1,0.1,0.1]}>  
            <A11y role="button" description="A typewriter on a table">
              <Typewriter 
                spring={springProps}
                onClick={() => {
                  if (dragCancels() && !fade) {
                    setOnionEye([0,-0.2,0]);
                    setCurrentChat(CHAT_NAME.typewriter);
                  }
                }} 
              />
            </A11y>
            <group position={[-3, 40, 0.2]}>
              {currentChat === CHAT_NAME.typewriter &&
              <Chat onDestroyed={resetChat} chats={Chats.typewriter()} />}
            </group>
          </group>
          <group position={[-0.5, -1.27, 0.4]} rotation={[0,0.4,0]}>  
            <Notebook spring={springProps} />
          </group>
        </group>

        <group position={[-8,-2.05,3.1]} rotation={[0,0.3,0]} scale={[0.01,0.01,0.01]}>  
          <A11y role="button" description="A phone on a table">
            <Phone 
              spring={springProps}
              onClick={() => {
                if (dragCancels() && !fade) {
                  setOnionEye([0,-0.25,0]);
                  setCurrentChat(CHAT_NAME.telephone);
                }
              }}
            />
          </A11y>
        </group>
        <group position={[-25, 1.75, 0.2]}>
          {currentChat === CHAT_NAME.telephone &&
          <Chat onDestroyed={resetChat} chats={Chats.telephone(() => showModal(`contact`))} />}
        </group>

        <group position={[-7,-2.82,0.5]} rotation={[0,0,0]} scale={[0.009,0.009,0.009]}>  
          <Chair spring={springProps} />
        </group>

        <group position={[2.5, 1, -7]} rotation={[0,0,0]} scale={[0.125,0.125,0.125]}>  
          <A11y role="button" description="A painting hanging on the wall">
            <WallPainting 
              spring={springProps} 
              onClick={() => {
                if (dragCancels() && !fade) {
                  setOnionEye([0,0.1,0.3]);
                  showModal(`credits`);
                }
              }} 
            />
          </A11y>
        </group>

        <group position={[4.5, -4.75, -2.8]} rotation={[0,Math.PI/2,0]} scale={[3.5,3.5,3.5]}>  
          <A11y role="button" description="A window">
            <Window 
              spring={springProps}
              onClick={() => {
                if (dragCancels() && !fade) {
                  setOnionEye([0,0.3,0.1]);
                  setCurrentChat(CHAT_NAME.window);
                }
              }}
            />
          </A11y>
        </group>
        <group position={[4.5, 0.25, 0.2]}>
          {currentChat === CHAT_NAME.window &&
          <Chat onDestroyed={resetChat} chats={Chats.window(history)} />}
        </group>
      </Suspense>

      <Switch>
        <Route path={`${path}/credits`}>
          <Html>
            <Credits close={() => hideModal()} />
          </Html>
        </Route>
        <Route path={`${path}/bookshelf`}>
          <Html>
            <BookshelfModal close={() => hideModal()} />
          </Html>
        </Route>
        <Route path={`${path}/contact`}>
          <Html>
            <Contacts close={() => hideModal()} />
          </Html>
        </Route>
      </Switch>

      {isLoaded.current && <Stars
        depth={5}
        count={10000}
        factor={6}
        fade
      />}

      <PerspectiveCamera
        makeDefault={true}
        far={2000000}
        near={0.1}
        fov={50}
        zoom={0.75}
        position={[5.45, -0.7, 5.1]}
        rotation={[-0.209149, 0.69613, 0.135278]}>
        <directionalLight intensity={0.25} decay={2} color="#ffeedd" position={[-9.59, 4.79, 4.79]} />
      </PerspectiveCamera>
      <ambientLight 
        color="#ffeedd"      
        intensity={0.6}  
      />
      <directionalLight
        intensity={0.35}
        decay={2}
        color="#ffeedd"
        position={[7, 3, 8]}
      />
      <directionalLight
        intensity={0.5}
        decay={2}
        color="#ffeedd"
        position={[5, 3, 0]}
      />
      <directionalLight
        intensity={0.3}
        decay={2}
        color="#ffeedd"
        position={[0, 7, 0]}
      />
    </>
  );
}

export default LivingRoom;
