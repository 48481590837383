import React from 'react';
import { FaTwitterSquare, FaLinkedin, FaGithubSquare, FaInstagramSquare, FaEnvelopeSquare } from 'react-icons/fa';
import BaseModal from './baseModal';

const style = {
  content: {
    maxWidth: 300,
    maxHeight: 350,    
  }
};

function Contact(props) {
  return (<BaseModal
    close={props.close}
    contentLabel="Contact"
    style={style}
  >
    <h1 className="modal-title">Contact</h1>

    <svg width="0" height="0">
      <radialGradient id="rg" r="150%" cx="30%" cy="107%">
        <stop stopColor="#fdf497" offset="0" />
        <stop stopColor="#fdf497" offset="0.05" />
        <stop stopColor="#fd5949" offset="0.45" />
        <stop stopColor="#d6249f" offset="0.6" />
        <stop stopColor="#285AEB" offset="0.9" />
      </radialGradient>
    </svg>

    <div className="contacts-list">
      <a href="https://twitter.com/ryapapap" target="_blank" rel="noreferrer" className="twitter"><FaTwitterSquare /></a>
      <a href="https://instagram.com/ryapapap" target="_blank" rel="noreferrer" className="insta"><FaInstagramSquare /></a>
      <a href="https://www.linkedin.com/in/ryan-patterson-ba7b68111/" target="_blank" rel="noreferrer" className="linkedin"><FaLinkedin /></a>
      <a href="https://github.com/ryapapap" target="_blank" rel="noreferrer" className="github"><FaGithubSquare /></a>
      <a href="mailto:ryapapap@gmail.com?Subject=Hi 👋" className="email"><FaEnvelopeSquare /></a>
    </div>
  </BaseModal>);
}

export default Contact;
