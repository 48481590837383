/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Bench/model.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0.16, 1.67, 0.13]}>
        <group position={[-0.16, -1.67, -0.13]}>
          <mesh material={materials.BlocksPaper} geometry={nodes['node_MeshObject-400393664-PolyPaper20'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Bench/model.gltf')
