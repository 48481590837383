/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { animated } from 'react-spring/three';
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model({spring, ...props}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/WallPainting/Wall_Art_Classical_01.gltf')
  materials.DD9944.transparent = true;
  materials['1A1A1A'].transparent = true;
  materials['4CAF50'].transparent = true;
  materials['8BC34A'].transparent = true;
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.03, 6.71, 0.23]} rotation={[Math.PI, 0, Math.PI]}>
        <group position={[0.03, -6.71, -0.23]}>
          <animated.mesh {...spring} material={materials.DD9944} geometry={nodes['buffer-0-mesh-0'].geometry} />
          <animated.mesh {...spring} material={materials['1A1A1A']} geometry={nodes['buffer-0-mesh-0_1'].geometry} />
          <animated.mesh {...spring} material={materials['4CAF50']} geometry={nodes['buffer-0-mesh-0_2'].geometry} />
          <animated.mesh {...spring} material={materials['8BC34A']} geometry={nodes['buffer-0-mesh-0_3'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/WallPainting/Wall_Art_Classical_01.gltf')
