/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model(props) {
  const group = useRef()
  const { nodes } = useGLTF('/models/Skeleton/skeleton.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.18, -4.88, -0.59]}>
        <group position={[0.18, 4.88, 0.59]}>
          <mesh material={nodes['node-0'].material} geometry={nodes['node-0'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Skeleton/skeleton.gltf')
