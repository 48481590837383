import React from 'react';
import _ from 'lodash';
import Modal from 'react-modal';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { modalStyles } from './modalUtils';
import './modal.scss';

function BaseModal(props) {
  return (<Modal
    isOpen={true}
    onRequestClose={props.close}
    contentLabel={props.contentLabel}
    style={_.merge({}, modalStyles, props.style)}
  >
    <button className="close-button" onClick={props.close}><AiOutlineCloseCircle /></button>
    <div className="modal-container">
      {props.children}
    </div>
  </Modal>);
}

export default BaseModal;
