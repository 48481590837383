/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef, useState } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'
import { useSpring, animated } from 'react-spring/three';

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Onion/onion.gltf')

  // LOL
  const [breathing, setBreathing] = useState(true);
  const scale = useSpring({
    scale: breathing ? [1.02,1.02,1.02] : [1,1,1],
    config: { duration: 3000 },
    onRest: () => setBreathing(b => !b),
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0.13, -0.02, -0.25]}>
        <group position={[-0.13, 0.02, 0.25]}>
          {/* <mesh material={materials.lambert3SG} geometry={nodes['buffer-0-mesh-0'].geometry} /> */}
          <animated.mesh {...scale} material={materials.lambert4SG} geometry={nodes['buffer-0-mesh-0_2'].geometry} />
          <animated.mesh {...scale} material={materials.lambert2SG} geometry={nodes['buffer-0-mesh-0_3'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Onion/onion.gltf')
