/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { animated } from 'react-spring/three';
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model({spring, ...props}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Sofa/Sofa_01.gltf');
  materials.F44336.transparent = true;
  materials.F44336.alphaTest = 0.1;
  materials['795548'].transparent = true;
  materials['795548'].alphaTest = 0.1;
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.9, 3.93, 0.03]}>
        <group position={[0.9, -3.93, -0.03]}>
          <animated.mesh {...spring} material={materials.F44336} geometry={nodes['buffer-0-mesh-0'].geometry} />
          <animated.mesh {...spring} material={materials['795548']} geometry={nodes['buffer-0-mesh-0_1'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Sofa/Sofa_01.gltf')
