function onionMan(history) {
  return [
    `are you ready to go back inside?`,
    [
      {
        text: 'yep',
        result: [{ 
          type: 'interaction', 
          interaction: () => {
            history.push('/inside');
          }
        }],
      },
      {
        text: 'not yet',
        result: ['ok, take your time'],
      }
    ]
  ]
}

function wishingWell(castCoin) {
  return [
    'do you want to make a wish?',
    [
      {
        text: 'yeah',
        result: [
          `ok, cast your coin when you're ready`,
          [
            {
              text: '*cast*',
              hideOnSelect: true,
              result: [{ type: 'interaction', interaction: castCoin }],
            }
          ]
        ]
      },
      {
        text: 'not today',
        result: ['ok'],
      }
    ]
  ]
}

function playground() {
  return [
    `every now and then I'll make some silly project`,
    `like a couple years ago I was hosting dinner parties like a restaurant called [Wen Noodle Shop](https://jovial-lichterman-8983c6.netlify.app/)`,
    `I'd like to share projects like that here, but, uh...`,
    `it's not ready yet`,
  ]
}

function garden() {
  return [
    `I really like how some people share their notes online`,
    `stuff like [Maggie Appleton's notes](https://maggieappleton.com/garden-history) or [this workshop by Willa Köerner](https://willakoerner.com/)`,
    `Last summer I started in a Notion doc. I wanted to update with stuff I've thought about and do some editing. Maybe my opinion has changed`,
    `but if you're interested [you can take a look](https://phase-acorn-c6a.notion.site/intro-494b0a0d37c94e858885ffbbd93e5ddf) and let me know what you think`
  ]
}

export const CHAT_NAME = {
  garden: "garden",
  onionMan: "onionMan",
  wishingWell: "wishingWell",
  playground: "playground",
};

const chats = {
  garden,
  onionMan,
  wishingWell,
  playground,
};

export default chats;
