import React from 'react';
import { TextureLoader } from 'three';
import { useLoader } from 'react-three-fiber';
import { useSpring, animated } from 'react-spring/three';
import Onion from '../models/Onion';

function OnionMan(props) {
  const eyeTexture = useLoader(TextureLoader, '/textures/eye.jpg'); 

  // range: is 0.3 . . .
  const eyeRotation = useSpring({
    rotation: props.eyeRotation || [0,0,0],
  });

  // if i want to add blinking, should check out this so post:
  // https://stackoverflow.com/questions/12425014/create-a-concave-half-sphere-with-three-js

  return (<>
    <group position={[4.9, -1.4, -4.4]} rotation={[0,0,0]} scale={[1.25,1.25,1.25]}>  
      <Onion {...props} />
    </group>
    <group position={[3.15, -0.95, -3.5]} rotation={[-0.5,-1.6,0]} scale={[0.2,0.2,0.2]}>  
      <animated.mesh {...eyeRotation} onClick={props.onClick} visible userData={{ test: "hello" }} position={[0, 0, 0]}>
        <sphereGeometry attach="geometry" args={[1, 16, 16]} />
        <meshStandardMaterial
          attach="material"
          map={eyeTexture}
        />
      </animated.mesh>
    </group>
    <group position={[3.85, -0.95, -3.5]} rotation={[-0.5,-1.5,0]} scale={[0.2,0.2,0.2]}>  
      <animated.mesh {...eyeRotation} onClick={props.onClick} visible userData={{ test: "hello" }} position={[0, 0, 0]}>
        <sphereGeometry attach="geometry" args={[1, 16, 16]} />
        <meshStandardMaterial
          attach="material"
          map={eyeTexture}
        />
      </animated.mesh>
]    </group>
    <group position={[3.55, -1.75, -3.24]} rotation={[0,0,0]} scale={[0.45,0.125,0.15]}>  
      <mesh onClick={props.onClick} visible userData={{ test: "hello" }} position={[0, 0, 0]}>
      <sphereGeometry attach="geometry" args={[1, 16, 16]} />
      <meshStandardMaterial
        attach="material"
        color="red"
        transparent
        roughness={0.5}
        metalness={0.1}
      />
      </mesh>
    </group>
  </>);
}

export default OnionMan;
