/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

const flowerColors = [
  0xfbd1fb,
  0x6b3395,
  0xc483a9,
  0xd4ac36,
  0x4c642c,
  0x875060,
  0xbe7259,
  0xea5329,
];


export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Flower/Flower_01.gltf')

  const color = useRef(Math.floor(Math.random() * Math.floor(flowerColors.length)))

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.66, -9.94, 193.62]} rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[0.66, 9.94, -193.62]}>
          <mesh geometry={nodes['buffer-0-mesh-0'].geometry}>
            <meshBasicMaterial 
              attach="material" 
              color={flowerColors[color.current]}
              roughness={0.1} 
              metalness={1} 
            />
          </mesh>
          <mesh material={materials.blinn2SG} geometry={nodes['buffer-0-mesh-0_1'].geometry} />
          <mesh material={materials.blinn9SG} geometry={nodes['buffer-0-mesh-0_2'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Flower/Flower_01.gltf')
