/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Coin/Coin.gltf')
  nodes['node-0'].geometry.computeBoundingBox();
  nodes['node-0'].geometry.center();
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh material={materials.phong1SG} geometry={nodes['node-0'].geometry} />
    </group>
  )
}

useGLTF.preload('/models/Coin/Coin.gltf')
