/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { animated } from 'react-spring/three';
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model({spring, ...props}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Book/CHAHIN_NOTEBOOK.gltf')
  materials['Material.009'].transparent = true;
  materials['Material.006'].transparent = true;
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0.08, -0.01, 0.03]}>
        <group position={[-0.08, 0.01, -0.03]}>
          <animated.mesh {...spring} material={materials['Material.009']} geometry={nodes['buffer-0-mesh-0'].geometry} />
          <animated.mesh {...spring} material={materials['Material.006']} geometry={nodes['buffer-0-mesh-0_1'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Book/CHAHIN_NOTEBOOK.gltf')
