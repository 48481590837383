/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { animated } from 'react-spring/three';
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model({spring, ...props}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Chair/WoodChair_01.gltf')
  materials.blinn2SG.transparent = true;
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-6.46, -0.76, 136.8]} rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[6.46, 0.76, -136.8]}>
          <animated.mesh {...spring} material={materials.blinn2SG} geometry={nodes['node-0'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Chair/WoodChair_01.gltf')
