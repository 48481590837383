/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/FlowerTulips/tulips.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.02, 1.5, 2.97]}>
        <group position={[0.02, -1.5, -2.97]}>
          <mesh position={[0,0,0]} material={materials.lambert2SG} geometry={nodes['buffer-0-mesh-0'].geometry} />
          <mesh position={[0,0,0]} material={materials.lambert7SG} geometry={nodes['buffer-0-mesh-0_1'].geometry} />
          <mesh position={[0,0,0]} material={materials.lambert8SG} geometry={nodes['buffer-0-mesh-0_2'].geometry} />
          <mesh position={[0,0,0]} material={materials.lambert3SG} geometry={nodes['buffer-0-mesh-0_3'].geometry} />
          <mesh position={[0,0,0]} material={materials.lambert4SG} geometry={nodes['buffer-0-mesh-0_4'].geometry} />
          <mesh position={[0,0,0]} material={materials.lambert5SG} geometry={nodes['buffer-0-mesh-0_5'].geometry} />
          <mesh position={[0,0,0]} material={materials.lambert6SG} geometry={nodes['buffer-0-mesh-0_6'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/FlowerTulips/tulips.gltf')
