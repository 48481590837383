import React, { Suspense, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TextureLoader, RepeatWrapping } from 'three';
import { useLoader } from 'react-three-fiber';
import { A11y } from '@react-three/a11y';
import { PerspectiveCamera } from '@react-three/drei/PerspectiveCamera';
import { HtmlChatWrapper as Chat } from '../components/chat';
import EntranceChats from '../chats/entrance';

import Door from '../models/Door4';

function Wall() {
  const wallTexture = useLoader(TextureLoader, '/textures/wall.jpg'); 
  wallTexture.wrapS = RepeatWrapping;
  wallTexture.wrapT = RepeatWrapping;
  wallTexture.repeat.set( 10, 10 );

  return (<group position={[0, -4, 0]} rotation={[-Math.PI/2,0,0]}>  
    <mesh>
      <planeBufferGeometry attach="geometry" args={[40, 40]} />
      <meshStandardMaterial
        attach="material"
        map={wallTexture}
      />
    </mesh>
  </group>);
}

function DoorFrame() {
  const doorFrameTexture = useLoader(TextureLoader, '/textures/door_frame.jpg'); 

  return (<>
      <group position={[-2.9, 4.25, -2]}>  
      <mesh>
        <boxBufferGeometry attach="geometry" args={[6.5, 0.5, 1, 5,5,5]} />
        <meshStandardMaterial
          attach="material"
          map={doorFrameTexture}
        />
      </mesh>
    </group>
    <group position={[-5.9, -2, -2]}>  
      <mesh>
        <boxBufferGeometry attach="geometry" args={[0.5, 12, 1, 5,5,5]} />
        <meshStandardMaterial
          attach="material"
          map={doorFrameTexture}
        />
      </mesh>
    </group>
    <group position={[0.1, -2, -2]}>  
      <mesh>
        <boxBufferGeometry attach="geometry" args={[0.5, 12, 1, 5,5,5]} />
        <meshStandardMaterial
          attach="material"
          map={doorFrameTexture}
        />
      </mesh>
    </group>
  </>);
}

function Entrance() {
  const [showChat, setShowChat] = useState(false);
  const history = useHistory();
  
  return (
    <>
      <Suspense fallback={null}>

        <group position={[0, 0, 2]} rotation={[Math.PI/2,0,0]}> 
          <Wall />
        </group>

        <DoorFrame />
        <group position={[-3, -2, -2]} rotation={[0,Math.PI/2,0]}  scale={[3,3,3]}>  
          <A11y role="button" description="A door">
            <Door onClick={() => setShowChat(true)} />
          </A11y>
        </group>
        {showChat &&
          <group position={[0,3,0]}>
            <Chat chats={EntranceChats.door(history)} />
          </group>}

      </Suspense>

      <PerspectiveCamera
        makeDefault={true}
        far={2000000}
        near={0.1}
        fov={45}
        position={[-0.5, 0, 15]}
      >
        <directionalLight intensity={0.25} decay={2} color="#ffeedd" position={[-9.59, 4.79, 4.79]} />
      </PerspectiveCamera>
      <ambientLight 
        color="#ffeedd"      
        intensity={0.6}  
      />
      <directionalLight
        intensity={0.6}
        decay={2}
        color="#ffeedd"
        position={[5, 10, 10]}
      />
    </>
  );
}

export default Entrance;
