import Breathe from '../components/breathe';

function door(history) {
  const breatheYes = [
    'cool!',
    { type: 'component', Component: Breathe, wait: 7000 },
    'alright, come on in',
    [{
      text: '*go inside*',
      hideOnSelect: true,
      result: [{ 
        type: 'interaction', 
        interaction: () => {
          history.push('/inside');
        }
      }]
    }]
  ];

  const breatheNo = [
    `hmm, I don't think I want you to visit then.`,
    [{ text: 'ok, I changed my mind', result: breatheYes }]
  ];

  return [
    'Hey, thanks for coming',
    `normally I'd ask you to take your shoes off...`,
    `but since we're online, how about we take a deep breathe together?`,
    [
      { 
        text: 'ok',
        result: breatheYes,
      },
      { 
        text: 'no thanks',
        result: breatheNo,
      },
    ]
  ];
}

const chats = {
  door,
};

export default chats;
