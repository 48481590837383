import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Canvas } from 'react-three-fiber';
import { A11yAnnouncer } from '@react-three/a11y';
import LivingRoom from './rooms/Living';
import Outside from './rooms/Outside';
import Entrance from './rooms/Entrance';
import Closet from './rooms/Closet';

import './App.css';

function App() {
  return (
    <div className="App">
      <Canvas>
        <Router>
          <Switch>
            <Route path="/inside">
              <LivingRoom />
            </Route>
            <Route path="/outside">
              <Outside />
            </Route>
            <Route path="/closet">
              <Closet />
            </Route>
            <Route path="/">
              <Entrance />
            </Route>
          </Switch>
        </Router>
      </Canvas>
      <A11yAnnouncer />
    </div>
  );
}

export default App;
