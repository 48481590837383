import { PerspectiveCamera } from '@react-three/drei/PerspectiveCamera';
import { Sky } from '@react-three/drei'
import React, { Suspense } from 'react';

import Skeleton from '../models/Skeleton';
import CardboardBox from '../models/CardboardBox';

function Room(props) {
  const {width, length, height} = props;

  const floorColor = "black";

  return (<>
    <group position={[0, -height/2, 0]} rotation={[-Math.PI/2,0,0]}>  
      <mesh>
        <planeBufferGeometry attach="geometry" args={[width, length]} />
        <meshBasicMaterial attach="material" color={floorColor} roughness={0.1} metalness={1} />
      </mesh>
    </group>

  </>);
}

function Entrance() {
  return (
    <>
      <Suspense fallback={null}>

        <group position={[0, 0, -2]} rotation={[Math.PI/2,0,0]}  > 
          <Room 
            width={200}
            length={200}
            height={8}
          />
        </group>

        <group position={[-3, -2, 0]} rotation={[0,0,0]}  scale={[0.25,0.25,0.25]}>  
          <Skeleton />
        </group>
        <group position={[3, -17, 4]} rotation={[0,0.3,0]} scale={[12,12,12]}>  
          <CardboardBox />
        </group>

      </Suspense>

      <Sky
        distance={450000} // Camera distance (default=450000)
        sunPosition={[0, 1, 0]} // Sun position normal (defaults to inclination and azimuth if not set)
        inclination={0} // Sun elevation angle from 0 to 1 (default=0)
        azimuth={0.25} // Sun rotation around the Y axis from 0 to 1 (default=0.25)
        // All three/examples/jsm/objects/Sky props are valid
      />

      <fog attach="fog" args={["darkgray", 2, 45]} />

      <PerspectiveCamera
        makeDefault
        far={2000000}
        near={0.1}
        fov={45}
        position={[2,6,17]}
        rotation={[-0.5,0.25,0]}>
        <directionalLight intensity={0.25} decay={2} color="#ffeedd" position={[-9.59, 4.79, 4.79]} />
      </PerspectiveCamera>
      <ambientLight 
        color="#ffeedd"      
        intensity={0.6}  
      />
      <directionalLight
        intensity={0.35}
        decay={2}
        color="#ffeedd"
        position={[7, 3, 8]}
      />
      <directionalLight
        intensity={0.5}
        decay={2}
        color="#ffeedd"
        position={[5, 3, 0]}
      />
      <directionalLight
        intensity={0.3}
        decay={2}
        color="#ffeedd"
        position={[0, 7, 0]}
      />
    </>
  );
}

export default Entrance;
