import React, { useState } from 'react';
import _ from 'lodash';
import BaseModal from './baseModal';
import Chat from './chat';
import books from './books';
import './bookshelf.scss';

const afterChatAsk = (title) => [
  `do you have any thoughts to add?`,
  [
    {
      text: 'yes',
      result: [{ type: 'input', title, response: 'cool', },]
    },
    {
      text: 'no',
      result: ['ok']
    }
  ],
];

function Bookshelf(props) {
  const [currentChat, setCurrentChat] = useState(null);

  return (<BaseModal
    close={props.close}
    contentLabel="Bookshelf"
  >
    <div className="bookshelf-chat">
      <Chat
        key={currentChat}
        chats={currentChat}
      />
    </div>
    <div className="books-list">
      {_.map(books, ({category, chat, items}) => <div 
        key={category} 
        className="book-category"
      >
        <h1 onClick={() => setCurrentChat([...chat, ...afterChatAsk(category)])}>{category}</h1>
          {/* 
            using the container div so i can set inline-block for cursor: pointer
            and not have it take the whole line, while still having 1 per line :(
          */}
          <ul>
          {_.map(items, (item) => <li className="book-container" key={item.title}>
          <div
            onClick={() => {
              setCurrentChat([...item.chat, ...afterChatAsk(item.title)])
            }}
            className="book"
          >
            {item.title} {item.author ? `by ${item.author}` : ''}
          </div>
        </li>)}
        </ul>
      </div>)}
    </div>
  </BaseModal>);
}

export default Bookshelf;
