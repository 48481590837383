import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import snarkdown from 'snarkdown';
import _ from 'lodash';
import { ChatProps, CHAT_TYPE, IChatObj, IOption } from './utils';
import { replaceExternalLinks } from '../../utils';

export interface OptionsProps {
  options: IOption[];
  recur?: boolean;
}

// todo: my names are horrible. I wanna come back and redo.
// maybe recur -> !isChoice and hideOnSelect -> isAction ?
// trying to distinguish lists that can come back to select more
// than one option and the options that imply an action/something
// happens instead of showing the text . . .

const Options: React.FC<OptionsProps & ChatProps> = ({
  options,
  recur,
  push
}) => {
  const [selected, setSelected] = useState<number | null>(null);

  const onSelected = (i: number) => {
    if (selected === null) {
      setSelected(i);
      const msgs: IChatObj[] = [];

      if (!options[i].hideOnSelect) {
        msgs.push({
          type: CHAT_TYPE.message,
          msg: options[i].text,
          tail: true,
          side: 'right',
          wait: 750,
        });
      }

      msgs.push(...options[i].result);

      if (recur) {
        const remainingOptions = [...options];
        remainingOptions.splice(i, 1);
        if (remainingOptions) {
          msgs.push({
            type: CHAT_TYPE.option,
            options: remainingOptions,
            recur,
            // todo: not great: just using default here :/ will be ugly when i update it...
            delay: 750,
          });
        }
      }

      push(msgs);
    }
  }

  const style = useSpring({
    transform: 'scaleY(1.0)',
    from: {
      transform: 'scaleY(0.0)',
    },
    config: {
      mass: 1,
      tension: 380,
      friction: 27,
    },
  });

  // a little hacky, but I want
  // 2 or less options side by side,
  // >2 to list horizontally for now
  const questionsStyle: React.CSSProperties = options.length > 2 ?
   {
      flexBasis: 250,
      flexGrow: 1,
    } 
  : 
    {};

  if (selected !== null) {
    return null;
  }

  return (
    <animated.div 
      className="input-container"
      style={style}
    >
      <div className="chat-options">
        {_.map(options, (option, i) => 
            <button 
              key={i}
              className="chat-option" 
              onClick={() => onSelected(i)}
              style={questionsStyle}
              dangerouslySetInnerHTML={{ __html: replaceExternalLinks(snarkdown(option.text)) }}
            />
        )}
      </div>
    </animated.div>
  );
};

export default Options;
