/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Fountain2/model.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0.04, 1.46, -0.25]}>
        <group position={[-0.04, -1.46, 0.25]}>
          <mesh material={materials.BlocksPaper} geometry={nodes['node_MeshObject1311891456-PolyPaper22'].geometry} />
          <mesh material={materials.BlocksGlass} geometry={nodes['node_MeshObject1883970560-PolyGlass24'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Fountain2/model.gltf')
