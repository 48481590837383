/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { animated } from 'react-spring/three';
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model({spring, ...props}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/TableSide/model.gltf')
  materials.BlocksPaper.transparent = true;
  materials.BlocksPaper.alphaTest = 0.1;
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.52, 0.39, -0.63]}>
        <group position={[0.52, -0.39, 0.63]}>
          <animated.mesh {...spring} material={materials.BlocksPaper} geometry={nodes['node_MeshObject1331332352-PolyPaper20'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/TableSide/model.gltf')
