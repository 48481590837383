import { useRef, useEffect } from 'react';
import { useThree, useFrame } from 'react-three-fiber';
import { Euler } from 'three';

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  
  return ref.current;
}

export function clamp(val: number, min: number, max: number) {
  return Math.min(Math.max(val, min), max);
};

function distance(x: number, y: number): number {
  return Math.sqrt(x * x + y + y);
}

export function useCameraLogging() {
  const { camera } = useThree();
  useFrame(() => {
    console.log(camera.position);
    console.log(camera.rotation);
  });
}

export function useDragToLook(yBounds: [number, number], xBounds: [number, number], disable?: boolean) {
  const { camera } = useThree();
  
  const prevTouch = useRef<[number, number]>();
  useEffect(() => {
    function rotateCamera(dx: number, dy: number) {
      const euler = new Euler( 0, 0, 0, 'YXZ' );
      euler.setFromQuaternion( camera.quaternion );

      euler.y += dx;
      euler.x += dy;

      euler.y = clamp(euler.y, yBounds[0], yBounds[1]);
      euler.x = clamp(euler.x, xBounds[0], xBounds[1]);
  
      camera.quaternion.setFromEuler( euler );
      camera.updateProjectionMatrix();
    }


    const onMouseMove = (event: MouseEvent) => {
      if (event.buttons && !disable) {
        rotateCamera(event.movementX * 0.001, event.movementY * 0.001);
      }
    }

    const onTouchMove = (event: TouchEvent) => {
      const touch = event.touches[0];
      
      if (prevTouch.current) {
        const dx = touch.pageX - prevTouch.current[0];
        const dy = touch.pageY - prevTouch.current[1];
        rotateCamera(dx * 0.002, dy * 0.002);
      }

      prevTouch.current = [touch.pageX, touch.pageY]
    }

    const onTouchEnd = () => {
      prevTouch.current = undefined;
    }

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('touchend', onTouchEnd);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('touchmove', onTouchMove);
      window.addEventListener('touchend', onTouchEnd);
    };
  }, [camera, xBounds, yBounds, disable]);

  const draggedRef = useRef(0);
  useEffect(() => {
    const onMouseMove = (event: MouseEvent) => { draggedRef.current += distance(event.movementX, event.movementY); }
    const onMouseDown = () => { draggedRef.current = 0; }

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mousedown', onMouseDown);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mousedown', onMouseDown);
    };
  }, []);

  const dragCancels = () => draggedRef.current < 5;
  return dragCancels;
}

export const replaceExternalLinks = (message: string) => {
  const messageWrapper = document.createElement('div');
  messageWrapper.innerHTML = message;
  Array.from(messageWrapper.getElementsByTagName('a')).forEach(a => {
    if (a.hostname !== window.location.hostname) {
      a.setAttribute('target', '_blank');
      a.setAttribute('rel', 'noreferrer noopener');
    }
  })
  return messageWrapper.innerHTML;
}
