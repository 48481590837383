import React from 'react';
import { MessageProps } from './message';
import { InputProps } from './input';
import { OptionsProps } from './options';

export enum CHAT_TYPE {
  message = 'message',
  option = 'options',
  input = 'input',
  interaction = 'interaction',
  component = 'component',
  end = 'end',
}

interface ChatTiming {
  delay?: number;
  wait?: number;
}

export interface IMessage extends MessageProps, ChatTiming {
  type: typeof CHAT_TYPE.message;
}

export interface IOption {
  text: string;
  hideOnSelect?: boolean;
  result: IChatObj[];
}

export interface ILazyOption {
  text: string;
  result: IChat[];
}

export interface IOptionMessage extends OptionsProps, ChatTiming {
  type: typeof CHAT_TYPE.option;
}

export interface IInputMessage extends InputProps, ChatTiming {
  type: typeof CHAT_TYPE.input;
}

export interface IInteractionMessage extends ChatTiming {
  type: typeof CHAT_TYPE.interaction;
  interaction: () => void;
}

export interface IComponentMessage extends ChatTiming {
  type: typeof CHAT_TYPE.component;
  Component: React.FC;
}

export interface IEndMessage extends ChatTiming {
  type: typeof CHAT_TYPE.end;
}

export type IChatObj = IMessage | IOptionMessage | IInputMessage | IComponentMessage | IInteractionMessage | IEndMessage;
export type IChat = string | ILazyOption[] | IChatObj;

export interface ChatProps {
  push: (items: IChatObj[]) => void;
}
