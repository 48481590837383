
const modalPadding = 20;

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(97, 74, 74, 0.5)',
  },
  content: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    right: 'initial',
    bottom: 'initial',
    transform: 'translate(-50%, -50%)',
    borderRadius: '18px',
    border: 'none',
    padding: modalPadding,
    width: `calc(100% - ${modalPadding * 2}px)`,
    maxWidth: 700,
    height: `calc(100% - ${modalPadding * 2}px)`,
    maxHeight: 550,
  },
};
