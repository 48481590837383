import React, { useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import TextareaAutosize from 'react-textarea-autosize';
import { AiOutlineSend, AiOutlineClose } from 'react-icons/ai';
import { ChatProps, CHAT_TYPE } from './utils';

export interface InputProps {
  title: string;
  response: string;
}

const Input: React.FC<InputProps & ChatProps> = ({
  title,
  response,
  push
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [submitted, setSubmitted] = useState<string>('');

  const onSubmit = () => {
    if (inputRef && inputRef.current && inputRef.current.value) {
      fetch('/.netlify/functions/email', {
        method: 'POST',
        body: JSON.stringify({
          title,
          message: inputRef.current.value,
        }),
      });

      setSubmitted(inputRef.current.value);
      push([
        {
          type: CHAT_TYPE.message,
          msg: inputRef.current.value,
          tail: true,
          side: 'right',
        },
        { type: CHAT_TYPE.message, msg: response, delay: 1500, },
      ]);
    }
  }

  const onCancel = () => {
    setSubmitted('nevermind');
    push([
      {
        type: CHAT_TYPE.message,
        msg: `nevermind`,
        tail: true,
        side: 'right',
      },
      { type: CHAT_TYPE.message, msg: 'ok', delay: 750, },
    ]);
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      onSubmit();
    }
    if(event.key === 'Escape') {
      onCancel();
      event.stopPropagation();
    }
  }

  const style = useSpring({
    transform: 'scaleY(1.0)',
    from: {
      transform: 'scaleY(0.0)',
    },
    config: {
      mass: 1,
      tension: 380,
      friction: 27,
    },
  });

  if (submitted) {
    return null;
  }

  return (
    <animated.div 
      className="input-container"
      style={style}
    >
      <button 
        className="chat-cancel"
        onClick={onCancel}
      >
        <AiOutlineClose />
      </button>
      <div className="chat-input">
        <TextareaAutosize
          ref={inputRef}
          onKeyDown={onKeyPress}
          autoFocus
        />
        <button 
          className="chat-submit" 
          onClick={onSubmit}
        >
          <AiOutlineSend />
        </button>
      </div>
    </animated.div>
  );
};

export default Input;
