/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { animated } from 'react-spring/three';
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model({spring, ...props}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Door6/Door_01.gltf')
  materials.blinn2SG.transparent = true;
  materials.blinn2SG.alphaTest = 0.1;
  materials.blinn1SG.transparent = true;
  materials.blinn1SG.alphaTest = 0.1;
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-15.36, -1.28, 257.28]}>
        <group position={[15.36, 1.28, -257.28]}>
          <animated.mesh {...spring} material={materials.blinn2SG} geometry={nodes['buffer-0-mesh-0'].geometry} />
          <animated.mesh {...spring} material={materials.blinn1SG} geometry={nodes['buffer-0-mesh-0_1'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Door6/Door_01.gltf')
