/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Armchair/Armchair_01.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-1.08, 3.91, 0.04]}>
        <group position={[1.08, -3.91, -0.04]}>
          <mesh material={materials.F44336} geometry={nodes['buffer-0-mesh-0'].geometry} />
          <mesh material={materials['795548']} geometry={nodes['buffer-0-mesh-0_1'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Armchair/Armchair_01.gltf')
