import React from 'react';
import BaseModal from './baseModal'

function Credits(props) {
  return (<BaseModal
    close={props.close}
    contentLabel="Credits"
  >
    <div className="credits-container">
      <p>
        This project was possible because of open-source projects
        and assets. Shoutout to the maintainers of projects like
        Three.js, React-three-fiber, React-spring, React-icons (FontAwesome 
        and Ant Design Icons), and React (among others).
      </p>
      <p>
        I used assets from <a target="_blank" rel="noreferrer" href="https://poly.google.com/">Google Poly</a> that 
        were released with the <a target="_blank" rel="noreferrer" href="https://creativecommons.org/licenses/by/3.0/legalcode">CC-BY 3.0 license</a>.
        Google Poly will soon be defunct (google gonna google), but I'll link to 
        all assets I downloaded (not all of them are currently in use):
      </p>
      <ul>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/dfL6q_VTWVf">TableSide by Darwin Yamamoto</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/7b_tCaBRLCI">Fountain2 by Don Carson</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/dGkc5Kjh7EK">Skeleton by VR art studio</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/bb6XIhUUObg">CardboardBoxes by sirkitree</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/dwBpM-aSA_t">Window by Justin Randall</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/15B0ihXkg3C">Bookshelf by Wanja Pflüger</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/5UU85o2PgY6">Bench by Frank Lynam</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/ch-fQ-Ruyog">Door by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/aQp17eqOPFn">Door2 by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/3QQJG_xOysv">Door3 by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/dRvd7q2nO-6">Door4 by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/9ElP4aKTczP">Door5 by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/6sqMmqfwKip">Door6 by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/fDpegPgEB0j">MessageBoard by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/62zn39CRkbG">WallPainting by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/5rf3YuZfJAW">Pond2 by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/cYUMQWc-BOV">Pond by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/2LCcq8vhqJ3">Turtle by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/cOmfraybkoW">Frog by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/dDe3njWPbg0">Slide by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/e-IJdcqZH4p">Swingset by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/0-7U_RTHzKT">Junglegym by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/fUdp1FVq6QE">Seesaw by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/cWzIGar8RJL">ClothesHanger by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/esa-gfuZgup">Phone by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/fnTN9aw1q25">Table by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/8RW134iS2gW">Table2 by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/bwd4gui4RZ4">Sofa by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/bssBNm9rp8Q">Armchair by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/7Jl72KgiRl-">Chair by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/23XivszLV11">Lamp by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/0lfEqzVkp8a">Typewriter by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/4S1nr7WmUxm">Book by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/2zk0pGhndM4">Coffee by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/7J_ua1Ho1Gt">Kitchen by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/aFWefo0cEFo">Curtains by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/0nyBBKNmXnr">Pen by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/aXVPyk0zekf">Notebook by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/9Ptsg_xZt6B">Stationary by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/3SD07SGSMNy">Alien by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/5TDOcmDQlCV">Onion by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/1FSDzk-LRdA">TreeCherry by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/fCT73jIf5jN">TreePine by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/2PolZJUAMmk">TreeRubber by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/8OfF21t6NMa">TreeMaple by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/74_FuSwEd0E">TreeWillow by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/2S3-H6XeXyC">TreeSycamore by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/68OOL4zL6Co">TreeElm by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/ahT2_ZxtLIq">TreeSpruce by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/0DtsZfSTWzA">TreeOak by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/2acu5nrdDYl">TreeWillow2 by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/6pwiq7hSrHr">Tree by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/0V0bSIy2bRe">FlowerBase by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/ef9ozEPNGk5">FlowerOrchid by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/aaJtRIXws3U">FlowerTulips by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/1ufwWhb0Dlj">Ivy by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/9znAp0dJiS8">Flower by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/66Z3ABnPiVB">FlowerDesertLily by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/dYQFgjU5Eqx">FlowerDesertMarigold by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/ce4GXw3VYE5">FlowerSun by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/5dwGkhjKXIW">Coin by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/auSYhIqAZq0">Desk by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/3qh9saogdJd">Houseplant by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/1BtyNc_gX5a">Bonsai by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/esFOngb0uwl">TreeStump by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/3jql0qtape-">Stone by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/0Fl55ZzsVzT">Mountain by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/0vAJVcMEFdv">Stones by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/50q_bJkLcuq">Stone2 by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/62bVOJt7vHv">Stone3 by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/3FmsLxIx8Lc">Stone4 by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/3IwTPvL_EAX">Owl by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/7mrPbZToHh2">Waterfall by Poly by Google</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://poly.google.com/view/eYi3Lsk5Ylm">Fence by Poly by Google</a></li>
      </ul>
    </div>
  </BaseModal>);
}

export default Credits;
