/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 293297437
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Stones/stone.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0.03, -0.01, -0.4]}>
        <group position={[-0.03, 0.01, 0.4]}>
          <mesh material={materials.lambert4SG} geometry={nodes['buffer-0-mesh-0'].geometry} />
          <mesh material={materials.lambert5SG} geometry={nodes['buffer-0-mesh-0_1'].geometry} />
          <mesh material={materials.lambert6SG} geometry={nodes['buffer-0-mesh-0_2'].geometry} />
          <mesh material={materials.lambert3SG} geometry={nodes['buffer-0-mesh-0_3'].geometry} />
          <mesh material={materials.lambert2SG} geometry={nodes['buffer-0-mesh-0_4'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Stones/stone.gltf')
