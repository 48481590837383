import React from 'react';
// import _ from 'lodash';
import BaseModal from './baseModal';

function Playground(props) {
  // const [currentChat, setCurrentChat] = useState(null);

  return (<BaseModal
    close={props.close}
    contentLabel="Playground"
  >
    <div className="projects-list">
      <div>
        <div>photo ? </div>
        <h1>Project title</h1>
        <div>tag line . . .</div>
      </div>
    </div>
  </BaseModal>);
}

export default Playground;
