/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/TreeSpruce/SpruceTree.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.03, 7.34, -0.09]}>
        <group position={[0.03, -7.34, 0.09]}>
          <mesh material={materials.SpruceTree_mat} geometry={nodes['node-0'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/TreeSpruce/SpruceTree.gltf')
