/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { animated } from 'react-spring/three';
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model({spring, ...props}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Window/model.gltf')
  materials.BlocksGlass.transparent = true;
  materials.BlocksPaper.transparent = true;
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[1.19, 1.34, 0.71]}>
        <group position={[-1.19, -1.34, -0.71]}>
          <animated.mesh {...spring} material={materials.BlocksGlass} geometry={nodes['node_MeshObject-2093062656-PolyGlass24'].geometry} />
          <animated.mesh {...spring} material={materials.BlocksPaper} geometry={nodes['node_MeshObject53453696-PolyPaper20'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Window/model.gltf')
