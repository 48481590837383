/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Swingset/Swing.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.38, 55.32, 0.34]}>
        <group position={[0.38, -55.32, -0.34]}>
          <mesh material={materials.Mat} geometry={nodes['node-0'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Swingset/Swing.gltf')
