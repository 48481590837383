/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { animated } from 'react-spring/three';
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model({spring, ...props}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Houseplant/Houseplant.gltf')
  materials.Houseplant_mat1.transparent = true;
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0.02, 2.42, 0.06]}>
        <group position={[-0.02, -2.42, -0.06]}>
          <animated.mesh {...spring} material={materials.Houseplant_mat1} geometry={nodes['node-0'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Houseplant/Houseplant.gltf')
