import React, { Suspense, useEffect, useState, useRef } from 'react';
import { Switch, Route, useHistory, useRouteMatch } from "react-router-dom";
import { TextureLoader, RepeatWrapping } from 'three';
import { useLoader, useFrame } from 'react-three-fiber';
import { Html, Sky, PerspectiveCamera } from '@react-three/drei';
import { A11y } from '@react-three/a11y';
import { HtmlChatWrapper as Chat } from '../components/chat';
import Chats, {CHAT_NAME} from '../chats/outside';
import { useDragToLook } from '../utils';

import Fountain from '../models/Fountain2';
import Coin from '../models/Coin';

import Bench from '../models/Bench';

import Swingset from '../models/Swingset';
import Slide from '../models/Slide';
import Seesaw from '../models/Seesaw';

import Flower from '../models/Flower';
import FlowerDesertLily from '../models/FlowerDesertLily';
import FlowerTulips from '../models/FlowerTulips';

import Tree from '../models/Tree';
import TreeRubber from '../models/TreeRubber';
import TreeSpruce from '../models/TreeSpruce';

import Stones from '../models/Stones';

import OnionMan from '../components/onionMan';
import PlaygroundModal from '../components/playground';

function Ground(props) {
  const groundTexture = useLoader(TextureLoader, '/textures/ground.jpg'); 
  groundTexture.wrapS = RepeatWrapping;
  groundTexture.wrapT = RepeatWrapping;
  groundTexture.repeat.set( 100, 100 );

  const {width, length, height} = props;

  return (<>
    <group position={[0, -height/2, 0]} rotation={[-Math.PI/2,0,0]}>  
      <mesh>
        <planeBufferGeometry attach="geometry" args={[width, length]} />
        <meshBasicMaterial 
          attach="material" 
          map={groundTexture}
        />
      </mesh>
    </group>

  </>);
}

function Outside() {
  const [casting, setCasting] = useState(false);
  const [currentChat, setCurrentChat] = useState();
  const history = useHistory();
  const { path } = useRouteMatch();
  const isBaseRoom = history.location.pathname === path;

  const resetChat = () => {
    setCurrentChat(null);
  }
  /*
  const showModal = (url) => {
    resetChat();
    history.replace(`${path}/${url}`);
  };
  */
  const hideModal = () => {
    history.replace(`${path}`);
  }

  useEffect(() => {
    const onEscape = (event) => {
      if(event.key === 'Escape') {
        setCurrentChat(null);
      }
    };

    window.addEventListener("keydown", onEscape, false);
    return () => {
      window.removeEventListener("keydown", onEscape, false);
    }
  }, []);

  const dragCancels = useDragToLook([-0.5, 1.5], [-0.3, 0.35], !isBaseRoom);

  const mesh = useRef();
  const initialVelocity = 5;
  const velocity = useRef(initialVelocity);
  const frameTime = 0.01;
  const gravity = -9;
  useFrame(() => {
    if (casting && mesh.current) {
      mesh.current.rotation.z += 0.15;

      mesh.current.position.z -= 0.02;
      mesh.current.position.x -= 0.02;

      mesh.current.position.y += velocity.current * frameTime;
      velocity.current += gravity * frameTime;
    }
  });

  return (
    <>
      <Suspense fallback={null}>

        <group position={[0, 0, 2]}>  
          <Ground 
            width={1000}
            length={1000}
            height={8}
          />
        </group>

        <group position={[0, -12.51, 0]} rotation={[0,0,0]}  scale={[6,6,6]}>  
          <A11y role="button" description="A large fountain with coins in it, a wishing well.">  
            <Fountain 
            onClick={() => {
              if (dragCancels()) {
                setCasting(false);
                velocity.current = initialVelocity;
                setCurrentChat(CHAT_NAME.wishingWell);
              }
            }}
            />
          </A11y>
        </group>
        <group position={[1, -1.5, 0.2]}>
          {currentChat === CHAT_NAME.wishingWell &&
          <Chat onDestroyed={resetChat} chats={Chats.wishingWell(() => setCasting(true))} />}
        </group>

        {casting && 
          <group ref={mesh} position={[4,-3,2]} scale={[5,5,5]}>
            <Coin rotation={[0.5,0,0]} />
          </group>
        }

        <group position={[-6, -11.25, 3]} rotation={[0,Math.PI/2+0.5,0]}  scale={[5,5,5]}>  
          <Bench />
        </group>

        <group position={[-1, -1.45, 4.5]} scale={[0.75,0.75,0.75]} rotation={[0,1.8,0]}>
          <A11y role="button" description="A giant humanoid onion">
            <OnionMan
              onClick={() => {
                if (dragCancels()) {
                  setCurrentChat(CHAT_NAME.onionMan);
                }
              }}
            />
          </A11y>
        </group>
        <group position={[-10, -1, 2]}>
          {currentChat === CHAT_NAME.onionMan &&
          <Chat onDestroyed={resetChat} chats={Chats.onionMan(history)} />}
        </group>


        <group position={[5, -4, 0]} rotation={[0,0,0]}  scale={[0.8,0.8,0.8]}>  
          <Stones />
        </group>

        <group position={[8, -4, -8]} rotation={[0,0,0]}  scale={[0.04,0.04,0.04]}>  
          <Swingset />
        </group>
        <group position={[15, -4, -15]} rotation={[0,Math.PI/2-1.5,0]}  scale={[1,1,1]}>  
          <Slide />
        </group>
        <group position={[16, -2.5, -4]} rotation={[0,-Math.PI/2-0.7,0]}  scale={[0.65,0.65,0.65]}>  
          <Seesaw />
        </group>
        <A11y role="button" description="A playground with a swingset, slide, and seesaw">
          <mesh 
            position={[11.5,-2,-8]}
            onClick={() => {
              if (dragCancels()) {
                setCurrentChat(CHAT_NAME.playground);
                // showModal(`playground`);
              }
            }}
          >
            <boxBufferGeometry attach="geometry" args={[10, 5, 10]} />
            <meshBasicMaterial 
              transparent 
              opacity={0}
              attach="material" 
              color={'red'} 
              roughness={0.1} metalness={1} 
            />
          </mesh>
        </A11y>
        <group position={[12, 0, -10]}>
        {currentChat === CHAT_NAME.playground &&
          <Chat onDestroyed={resetChat} chats={Chats.playground()} />}
        </group>


        <group position={[-5, -3, -17]} rotation={[0,Math.PI+0.7,0]}  scale={[0.004,0.004,0.004]}>  
          <Flower />
          <Flower position={[0,0,0]} rotation={[0,0,0]} />
          <Flower position={[-500,0,0]} rotation={[0,Math.PI/4+0.3,0]}  />
        </group>
        <group position={[0, -3, -17]} rotation={[0,Math.PI+0.4,0]}  scale={[0.004,0.004,0.004]}>  
          <Flower position={[-500,0,-5]} rotation={[0,Math.PI/4,0]}  />
          <Flower position={[0,0,250]} rotation={[0,-Math.PI/4,0]}  />
        </group>
        <group position={[-5, -3, -10]} rotation={[0,0,0]}  scale={[0.004,0.004,0.004]}>  
          <Flower position={[-100,0,-600]} rotation={[0,Math.PI/2,0]} />
          <Flower position={[700,0,0]} rotation={[0,Math.PI-0.2,0]}  />
          <Flower position={[0,0,250]} rotation={[0,Math.PI,0]}  />
        </group>
        <group position={[2, -3, -8]} rotation={[0,Math.PI,0]}  scale={[0.004,0.004,0.004]}>  
          <Flower rotation={[0,0,0]} />
          <Flower position={[500,0,400]} rotation={[0,-0.7,0]}  />
        </group>

        <group position={[-4, -4, -15]} rotation={[0,0,0]}  scale={[0.2,0.2,0.2]}>  
          <FlowerDesertLily />
          <FlowerDesertLily position={[18,0,-5]} rotation={[0,Math.PI/4,0]} />
          <FlowerDesertLily position={[13,0,12]} rotation={[0,Math.PI,0]} />
        </group>
        <group position={[0, -3.75, -10]} rotation={[0,0,0]}  scale={[0.2,0.2,0.2]}>  
          <FlowerTulips position={[-10,0,-40]} rotation={[0,-Math.PI/2,0]} />
          <FlowerTulips position={[5,0,-15]} rotation={[0,-Math.PI-0.2,0]} />
          <FlowerTulips position={[-20,0,-10]} rotation={[0,-Math.PI/4,0]} />
        </group>
        <A11y role="button" description="A bed of flowers. Cosmos, tulips, and desert lillies">
          <mesh 
            position={[0,-3,-10]}
            onClick={() => {
              if (dragCancels()) {
                setCurrentChat(CHAT_NAME.garden);
              }
            }}
          >
            <boxBufferGeometry attach="geometry" args={[6, 1, 6]} />
            <meshBasicMaterial 
              transparent 
              opacity={0}
              attach="material" 
              color={'red'} 
              roughness={0.1} metalness={1} 
            />
          </mesh>
        </A11y>
        <group position={[0, 0, -10]}>
          {currentChat === CHAT_NAME.garden &&
          <Chat onDestroyed={resetChat} chats={Chats.garden()} />}
        </group>

        <group position={[-9, -4, 8]} rotation={[0,0,0]}  scale={[0.8,0.8,0.8]}>  
          <TreeRubber />
        </group>
        <group position={[-30, -4, 5]} rotation={[0,0,0]}  scale={[0.02,0.02,0.02]}>  
          <Tree />
        </group>
        <group position={[-20, -4, -2]} rotation={[0,-Math.PI/2,0]}  scale={[0.02,0.02,0.02]}>  
          <Tree />
        </group>
        <group position={[-25, -4, -12]} rotation={[0,Math.PI/4,0]}  scale={[0.8,0.8,0.8]}>  
          <TreeRubber />
        </group>
        <group position={[-14, -4, -35]} rotation={[0,-Math.PI/2,0]}  scale={[0.8,0.8,0.8]}>  
          <TreeRubber />
        </group>
        <group position={[5, -4, -20]} rotation={[0,-Math.PI/2,0]}  scale={[0.02,0.02,0.02]}>  
          <Tree />
        </group>
        <group position={[25, -4, -25]} rotation={[0,0,0]}  scale={[0.9,0.9,0.9]}>  
          <TreeSpruce />
        </group>
        <group position={[-20, -4, -20]} rotation={[0,0,0]}  scale={[0.9,0.9,0.9]}>  
          <TreeSpruce />
        </group>

      </Suspense>

      <Sky sunPosition={[1, 0.1, 0]} />

      <Switch>
        <Route path={`${path}/playground`}>
          <Html>
            <PlaygroundModal close={() => hideModal()} />
          </Html>
        </Route>
      </Switch>

      <PerspectiveCamera
        makeDefault={true}
        far={2000000}
        near={0.1}
        fov={45}
        position={[6.234, -1.373, 2.746]}
        rotation={[-0.256, 1, 0.2177]}>
        <directionalLight intensity={0.25} decay={2} color="#ffeedd" position={[-9.59, 4.79, 4.79]} />
      </PerspectiveCamera>
      <ambientLight 
        color="#ffeedd"      
        intensity={0.4}  
      />
      <directionalLight
        intensity={0.35}
        decay={2}
        color="#ffeedd"
        position={[7, 3, 8]}
      />
      <directionalLight
        intensity={0.5}
        decay={2}
        color="#ffeedd"
        position={[5, 3, 0]}
      />
      <directionalLight
        intensity={0.3}
        decay={2}
        color="#ffeedd"
        position={[0, 7, 0]}
      />
    </>
  );
}

export default Outside;
