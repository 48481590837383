import React, { useState, useRef, useEffect } from 'react';
import {useTransition, animated} from 'react-spring';
import { useFrame } from 'react-three-fiber';
import { Html } from '@react-three/drei';
import _ from 'lodash';
import { usePrevious } from '../../utils';
import Message from './message';
import Input from './input';
import Options from './options';
import { IChatObj, IChat, ILazyOption, CHAT_TYPE, IMessage, IInteractionMessage } from './utils';
import './chat.scss';

interface ChatManagerProps {
  chats: IChatObj[];
  onDestroyed?: () => void;
}

interface ChatState {
  stack: IChatObj[];
  chats: IChatObj[];
}

const Chat: React.FC<ChatManagerProps> = (props) => {
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [chatState, setChatState] = useState<ChatState>({
    stack: props.chats,
    chats: [],
  });

  const push = (items: IChatObj[]) => {
    setChatState((c) => ({
      ...c,
      stack: [...items, ...c.stack],
    }))
  }

  const prevStack = usePrevious(chatState.stack) || [];
  useEffect(() => {
    const next = () => {
      const popped = _.first(chatState.stack);
      if (popped) {
        const isInteraction = popped.type === CHAT_TYPE.interaction;
        const isEnding = popped.type === CHAT_TYPE.end;

        setChatState((c) => ({
          stack: isEnding ? [] : c.stack.slice(1, c.stack.length),
          chats: [...c.chats, popped],
        }));

        if (isInteraction) {
          (popped as IInteractionMessage).interaction();
        }
      }
    }

    const nextMsg = _.first(chatState.stack);
    const lastMsg = _.last(chatState.chats);
    
    const wait = (lastMsg && lastMsg.wait) || 0;
    const delay = (nextMsg && nextMsg.delay) || 0;

    const inputTypes = [CHAT_TYPE.option, CHAT_TYPE.input];

    const waitingOnInput = lastMsg && 
      inputTypes.includes(lastMsg.type) &&
      prevStack.length >= chatState.stack.length;

    let timeout: NodeJS.Timeout;
    if (!waitingOnInput) {
      if (nextMsg) {
        timeout = setTimeout(next, wait + delay);
      } else {
        timeout = setTimeout(() => setIsFinished(true), 2000);
      }
    }

    const onSpace = (event: KeyboardEvent) => {
      if(event.key === ' ' && !(lastMsg && inputTypes.includes(lastMsg.type))) {
        next();
      }
    };

    window.addEventListener("keydown", onSpace, false);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("keydown", onSpace, false);
    }
  // since prevStack is based on chat state, should be fine
  // eslint-disable-next-line
  }, [chatState]);

  // useScrollToBottom
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  });

  const {chats} = chatState;
  const chatProps = {push};

  const transitions = useTransition(!isFinished, null, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    onDestroyed: props.onDestroyed,
  });

  return (
    <>
    {transitions.map(({ item, key, props }) => (
    item && <animated.div className="chat" ref={scrollRef} key={key} style={props}>
      {_.map(chats, (chat, i) => {
        switch(chat.type) {
          case CHAT_TYPE.message:
            const tail = i === chats.length - 1 
              || ![CHAT_TYPE.message, CHAT_TYPE.interaction].includes(chats[i+1].type)
              || (chats[i] as IMessage).side !== (chats[i+1] as IMessage).side;

            return <Message key={i} tail={tail} {...chat} {...chatProps} />;
          case CHAT_TYPE.option:
            return <Options key={i} {...chat} {...chatProps} />;
          case CHAT_TYPE.input:
            return <Input key={i} {...chat} {...chatProps} />
          case CHAT_TYPE.component:
            const { Component } = chat;
            return <Component key={i} />;
          default:
            return null;
        }
      })}
    </animated.div>
  ))}
  </>);
}

/*
  I've tried a few ways of doing the timing of the chats,
  what feels good for now is a reading-time delay and a min delay, whichever is longer
*/

const READING_WPMS = 250 / 60 / 1000;
const MIN_WAIT = 750;

function processChatDefaults(chats: IChat[], isFirst=true): IChatObj[] {
  return _.map(chats, (chat, i) => {
    if (_.isString(chat)) {
      return {
        type: CHAT_TYPE.message,
        msg: chat as string,
        wait: Math.max((chat as string).split(' ').length / READING_WPMS, MIN_WAIT),
      };
    } else if (_.isArray(chat)) {
      const optionsArray = chat as ILazyOption[];
      return {
        type: CHAT_TYPE.option,
        options: _.map(optionsArray, ({result, ...rest}) => ({
          result: processChatDefaults(result, false),
          ...rest,
        })),
      }
    } else {
      if (chat.type === CHAT_TYPE.option) {
        return {
          ...chat,
          options: _.map(chat.options, ({result, ...rest}) => ({
            result: processChatDefaults(result, false),
            ...rest,
          })),
        };
      } else {
        return chat as IChatObj;
      }
    }
  });
}

interface ChatWrapperProps {
  chats: IChat[];
}

const ChatWrapper: React.FC<ChatWrapperProps> = ({chats, ...rest}) => {
  // TODO: Could do conversion during build
  // or postpone till later (with death by types)
  const chatObjs = processChatDefaults(chats);

  return (<Chat chats={chatObjs} {...rest} />);
}


export const HtmlChatWrapper: React.FC<ChatWrapperProps> = (props) => {
  // here's the goal: never clip off screen.
  // because we're using drei's html, we move around the world
  // we're going to calculate transform based on our size and window
  const chatRef = useRef<HTMLDivElement>(null);
  useFrame(() => {
    if (chatRef.current) {
      const chatRect = chatRef.current.getBoundingClientRect();

      const style = window.getComputedStyle(chatRef.current);
      const matrix = new DOMMatrix(style.transform);

      const x = Math.min(matrix.m41 + window.innerWidth - chatRect.right, 0) || Math.max(matrix.m41 - chatRect.left, 0);
      const y = Math.min(matrix.m42 + window.innerHeight - chatRect.bottom, 0) || Math.max(matrix.m42 - chatRect.top, 0);

      chatRef.current.style.transform = `translate(${x}px, ${y}px)`;
    }
  });

  return (<Html>
    <div ref={chatRef}>
      <ChatWrapper {...props} />
    </div>
  </Html>);
}

export default ChatWrapper;
