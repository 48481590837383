/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 293297437
*/

import React, { useRef } from 'react'
import { animated } from 'react-spring/three';
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model({spring, ...props}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Typewriter/Typewriter_01.gltf')
  materials.FFFFFF.transparent = true;
  materials['1A1A1A'].transparent = true;
  materials['78909C'].transparent = true;
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.04, 4.56, 2.96]}>
        <group position={[0.04, -4.56, -2.96]}>
          <animated.mesh {...spring} material={materials['1A1A1A']} geometry={nodes['buffer-0-mesh-0'].geometry} />
          <animated.mesh {...spring} material={materials['78909C']} geometry={nodes['buffer-0-mesh-0_1'].geometry} />
          <animated.mesh {...spring} material={materials.FFFFFF} geometry={nodes['buffer-0-mesh-0_2'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Typewriter/Typewriter_01.gltf')
