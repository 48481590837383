/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 293297437
*/

import React, { useRef } from 'react'
import { animated } from 'react-spring/three';
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model({spring, ...props}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Bookshelf/model.gltf');
  materials.BlocksPaper.transparent = true;
  materials.BlocksPaper.alphaTest = 0.1;
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-1.3, 2.4, -0.9]}>
        <group position={[1.3, -2.4, 0.9]}>
          <animated.mesh {...spring} material={materials.BlocksPaper} geometry={nodes['node_MeshObject-343695872-PolyPaper18'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Bookshelf/model.gltf')
