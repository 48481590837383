import { CHAT_TYPE } from "../components/chat/utils";

/*
todo: Interactions, maybe:
- reset drink?
- the notebook on the desk.. share some writing ?
*/

function placeDrink(drink) {
  return [
    { type: CHAT_TYPE.message, msg: `I'll put it on your right`, delay: 750},
    { type: CHAT_TYPE.interaction, interaction: drink, delay: 600},
  ]
}

function intro(setDrink) {
  return [
    `Make yourself at home`,
    `do you want something to drink? I've got tea and coffee`,
    [
      {
        text: `I want green tea`,
        result: [
          'ok',
          ...placeDrink(() => setDrink('green')),
        ]
      },
      {
        text: `how about black tea?`,
        result: [
          'sure thing',
          ...placeDrink(() => setDrink('black')),
        ]
      },
      {
        text: `coffee, black`,
        result: [
          'you got it',
          ...placeDrink(() => setDrink('coffee')),
        ]
      },
      {
        text: `no thanks`,
        result: [],
      },
    ]
  ];
}

function onionMan(fadeOut, fadeIn) {
  return [
    `what's up?`,
    {
      type: CHAT_TYPE.option,
      recur: true,
      options: [
      { 
        text: `are you.. an onion?`,
        result: [
          `yep!`,
          `I heard you could be anything you wanted on the internet, so I decided to be an onion`,
          [
            {
              text: `that's.. weird`,
              result: [
                `well, I don't remember asking for your opinion, so...`,
              ],
            },
            {
              text: `that's cool`,
              result: [
                `I know`,
              ],
            },
          ]
        ],
      },
      { 
        text: `what is this?`,
        result: [
          `My home on the web.`,
          `I've been thinking about things that don't translate well with technology`,
          `and I wondered if I could make a website that felt more like visiting a friend in real life.`
        ],
      },
      { 
        text: `who are you?`,
        result: [
          { type: CHAT_TYPE.message, msg: `umm`, delay: 750, wait: 2000},
          `My name's Ryan, I guess`,
        ],
      },
      { 
        text: `what are you doing?`,
        result: [
          `mostly just vibing`,
          `I quit my job last year`,
          `I had some plans, but they mostly fell through cuz of the state of the world`,
          `but I’ve been safe.. I feel fortunate for that`,
          `and I was able to think. That's mostly what I wanted.`,
          `but it's not like the things I think about have conclusions`,
          `it's mostly been about trying to understand my place in the world`,
          `which has been overwhelming this past year`,
          { type: CHAT_TYPE.interaction, interaction: fadeOut, delay: 1000},
          `but I'm trying to keep it in context`,
          `remember I'm pretty small in the grand scheme of things`,
          `and how important it is to not freeze up, retreat, and do nothing`,
          { type: CHAT_TYPE.interaction, interaction: fadeIn, delay: 2500},
          `so, anyways, I've been exploring some of my interests`,
          `around the ways we relate to each other and technology and writing`,
          `if you think about those things too, I'd love to hear what's been on your mind`,
          [
            { 
              text: 'sure!',
              result: [
                { type: 'input', title: 'on relating', response: `that's cool`, },
              ],
            },
            { 
              text: `I don't`,
              result: ['ah, well'],
            },
          ],
        ],
      },
      { 
        text: `where are you?`,
        result: [
          { type: CHAT_TYPE.message, msg: `I'm right here..`, delay: 500, wait: 1750},
          `unless.. you mean irl`,
          `in which case: I'm in the San Francisco Bay Area.`
        ],
      },
      { 
        text: `*something else...*`,
        hideOnSelect: true,
        result: [
          { type: 'input', title: 'general inquiry', response: 'hmm.. let me think about that', },
        ],
      },
      { 
        text: `nevermind`,
        result: [{ type: CHAT_TYPE.end }],
      },
    ]}
  ];
}

function bookshelf(showBookshelf) {
  return [
      `Feel free to take a look and let me know what you think about any of these books`,
      `I do feel a little weird about this list though`,
      `like not everything I've learned is from a book exactly`,
      `and I know I'm not the best at keeping this up to date`,
    [
      { 
        text: 'ok',
        result: [
          { type: CHAT_TYPE.interaction, interaction: showBookshelf},
        ],
      },
      { 
        text: 'nevermind',
        result: [],
      },
    ],
  ];
}

function typewriter() {
  return [
    `I've been writing a lot recently`,
    `You can see some of what I've written [on this website](https://www.poetrik.com/)`,
    `and I've got a twine game in the works`,
    `but I'm still trying to figure out the right form to work in and how to share the work`,
    `do you have any advice?`,
    [
      { 
        text: 'yes!',
        result: [
          { type: 'input', title: 'writing advice', response: 'awesome! thank you', },
        ],
      },
      { 
        text: 'nope',
        result: [
          `mm.. anyways, I'm trying to just keep focused and do the work. It's real`,
        ],
      },
    ],
  ];
}

function telephone(showContact) {
  return [
    `Feel free to reach out`,
    `but I'm not Very Online`,
    `or at least I'm trying not to be`,
    `and I'm mostly misusing these services`,
    [
      {
        text: `ok`,
        result: []
      }
    ],
    { type: CHAT_TYPE.interaction, interaction: showContact},
  ];
}

function closet() {
  return [
    `that's my closet. I wouldn't go in there if I were you`,
    `it's just.. that's where all the mess is.`,
    `I know I may look like an onion who has it all together`,
    `but I don't`
    /*
    I think I often keep writing a little longer than i should.
    cutting this off here so i don't mess it up, although,
    also thiinking of mentioning:
    how messy this project is (or all projects)
    how no matter how much i work on the mess, there's always more
    how moving through the world just makes more mess
    and maybe looking at my need to appear tidy? or
    the need to keep the mess locked away
    */
  ];
}

function window(history) {
  return [
    `hmm, you want to go outside?`,
    [
      { 
        text: `let's go!`,
        result: [{ 
          type: 'interaction', 
          interaction: () => {
            history.push('/outside');
          }
        }],
      },
      { 
        text: 'nah, just admiring the window',
        result: ['cool cool'],
      },
    ]
  ];
}

function plant() {
  return [
    `you know, I've been wanting to start gardening`,
    `or at least get a few more plants`,
    `but I don't even know where to start.`,
    `do you have any tips? or, like, common knowledge I should know?`,
    [
      { 
        text: 'yes!',
        result: [
          { type: 'input', title: 'plant tips', response: 'thank you!', },
        ],
      },
      { 
        text: 'nope',
        result: ['ah, okay'],
      },
    ],
  ];
}

export const CHAT_NAME = {
  intro: "intro",
  onionMan: "onionMan",
  bookshelf: "bookshelf",
  typewriter: "typewriter",
  telephone: "telephone",
  closet: "closet",
  window: "window",
  plant: "plant",
};

const chats = {
  intro,
  onionMan,
  bookshelf,
  typewriter,
  telephone,
  closet,
  window,
  plant,
};

export default chats;
