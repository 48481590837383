/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
GOOGLE_processor_version: 294144208
*/

import React, { useRef } from 'react'
import { animated } from 'react-spring/three';
import { useGLTF } from '@react-three/drei/useGLTF'

export default function Model({spring, ...props}) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/Table/Table_01.gltf')
  materials.blinn10SG.transparent = true;
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-1.09, -17.42, 124.78]} rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[1.09, 17.42, -124.78]}>
          <animated.mesh {...spring} material={materials.blinn10SG} geometry={nodes['node-0'].geometry} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/Table/Table_01.gltf')
